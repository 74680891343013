const joinFee = '0.001';

const polygonTokenAddress = "0x6B370a0148328bb25228BE9C1f0b5B5272083257";
const polygonEverestAddress = "0x574499F23d1f6F61AF26c6B51EC4B4C2d62D1827";

const celoTokenAddress = "0x82Cfdb353b8e0Ac9C96728e88f8c9D840f2296CD";
const celoEverestAddress = "0x6B370a0148328bb25228BE9C1f0b5B5272083257";

const ethTestTokenAddress = "0xa5CBFE760666107BE09c8C89448eE233fb490D98";
const ethTestEverestAddress = "0xA0580390eb830a04e1f1CE1c868c4cb1d655d982";

const avaxTestTokenAddress = "0xECC36bb16A95904A7954315Fc4316B439ACf4617";
const avaxTestEverestAddress = "0xE58F608Ef0f36373Ef4A9AECe3F7CC29A6F3c41e";

const maticTestTokenAddress = "0x5EFC2162eF05665cfE15f656E2aC0b2826D9a7ff";
const maticTestEverestAddress = "0xcE17A003F45C18C9b9b2abb513DD8DB81b596cBC";

const bscTestTokenAddress = "0x82Cfdb353b8e0Ac9C96728e88f8c9D840f2296CD";
const bscTestEverestAddress = "0x6B370a0148328bb25228BE9C1f0b5B5272083257";

const velasTestTokenAddress = "0x82Cfdb353b8e0Ac9C96728e88f8c9D840f2296CD";
const velasTestEverestAddress = "0x6B370a0148328bb25228BE9C1f0b5B5272083257";

const celoTestTokenAddress = "0x82Cfdb353b8e0Ac9C96728e88f8c9D840f2296CD";
const celoTestEverestAddress = "0x6B370a0148328bb25228BE9C1f0b5B5272083257";


const sessionLength = 14400; //in seconds

//toString(16) (hex) return always lowercase
const chainIDs = ['0x4', '0xa869', '0x89', '0x13881', '0x61', '0x6f', '0xa4ec', '0xaef3']; //testnets for testing purposes only

//const networkNameMap = {'0x1':'Ethereum_Mainnet', '0x4':'Rinkeby_Testnet', '0xa86a':'Avalanche_Mainnet', '0xa869':'Avalanche_Testnet', '0x89':'polygon', '0x13881':'polygon_testnet', '0x38':'bsc', '0x61': 'bsc_testnet'}; 
const networkNameMap = {'0x4':'Rinkeby_Testnet', '0xa869':'Avalanche_Testnet', '0x89':'polygon', '0x13881':'polygon_testnet', '0x61': 'bsc_testnet', '0x6f': 'velas_testnet', '0xa4ec':'celo', '0xaef3': 'celo_testnet'}; //testnets for testing purpose


const networks = {
    Ethereum_Mainnet:{
        chainId: '0x1',
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
          name: 'Ethereum',
          symbol: 'ETH',
          decimals: 18
        },
        rpcUrls: ['https://rpc.ankr.com/eth'],
        blockExplorerUrls: ['https://etherscan.io/']
      },

    Rinkeby_Testnet:{
        chainId: '0x4',
        chainName: 'Rinkeby Testnet',
        nativeCurrency: {
          name: 'Rinkeby Ethereum',
          symbol: 'RIN',
          decimals: 18
        },
        rpcUrls: ['https://eth-rinkeby.alchemyapi.io/v2/AAvjfzfmalyyfoOyH3LcPF1ji0P-Pr24'],
        blockExplorerUrls: ['https://rinkeby.etherscan.io/']
    },

    Avalanche_Mainnet: {
        chainId: '0xa86a',
        chainName: 'Avalanche Mainnet C-Chain',
        nativeCurrency: {
          name: 'Avalanche',
          symbol: 'AVAX',
          decimals: 18
        },
        rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://snowtrace.io/']
    },

    Avalanche_Testnet: {
        chainId: '0xa869',
        chainName: 'Avalanche Testnet C-Chain',
        nativeCurrency: {
          name: 'Avalanche',
          symbol: 'AVAX',
          decimals: 18
        },
        rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://testnet.snowtrace.io/']
    },

    polygon: {
      chainId: `0x${Number(137).toString(16)}`,
      chainName: "Polygon Mainnet",
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18
      },
      rpcUrls: ["https://polygon-mainnet.g.alchemy.com/v2/CMVHRiH_yqLoGshHwD7wgC4x-LwZtByr"],
      //rpcUrls: ["https://polygon-rpc.com/"],
      blockExplorerUrls: ["https://polygonscan.com/"]
    },

    polygon_testnet: {
        chainId: `0x${Number(80001).toString(16)}`,
        chainName: "Polygon Mumbai Testnet",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18
        },
        rpcUrls: ["https://rpc-mumbai.matic.today"],
        blockExplorerUrls: ["https://polygonscan.com/"]
    },
  

    bsc: {
      chainId: `0x${Number(56).toString(16)}`,
      chainName: "Binance Smart Chain Mainnet",
      nativeCurrency: {
        name: "Binance Chain Native Token",
        symbol: "BNB",
        decimals: 18
      },
      rpcUrls: [
        "https://bsc-dataseed1.binance.org",
        "https://bsc-dataseed2.binance.org",
        "https://bsc-dataseed3.binance.org",
        "https://bsc-dataseed4.binance.org",
        "https://bsc-dataseed1.defibit.io",
        "https://bsc-dataseed2.defibit.io",
        "https://bsc-dataseed3.defibit.io",
        "https://bsc-dataseed4.defibit.io",
        "https://bsc-dataseed1.ninicoin.io",
        "https://bsc-dataseed2.ninicoin.io",
        "https://bsc-dataseed3.ninicoin.io",
        "https://bsc-dataseed4.ninicoin.io",
        "wss://bsc-ws-node.nariox.org"
      ],
      blockExplorerUrls: ["https://bscscan.com"]
    },

    bsc_testnet: {
        chainId: `0x${Number(97).toString(16)}`,
        chainName: "Binance Testnet",
        nativeCurrency: {
          name: "Binance Chain Native Token",
          symbol: "BNB",
          decimals: 18
        },
        rpcUrls: [
          "https://data-seed-prebsc-1-s1.binance.org:8545/"
        ],
        blockExplorerUrls: ["https://testnet.bscscan.com"]
    },

    velas_testnet: {
      chainId: `0x${Number(111).toString(16)}`,
      chainName: "Velas Testnet",
      nativeCurrency: {
        name: "Velas Chain Native Token",
        symbol: "VLX",
        decimals: 18
      },
      rpcUrls: [
        "https://testnet.velas.com/rpc/"
      ],
      blockExplorerUrls: ["https://evmexplorer.testnet.velas.com"]
    },

    celo: {
      chainId: `0x${Number(42220).toString(16)}`,
      chainName: "Celo Mainnet",
      nativeCurrency: {
        name: "Celo",
        symbol: "CELO",
        decimals: 18
      },
      rpcUrls: [
        "https://forno.celo.org"
      ],
      blockExplorerUrls: ["https://explorer.celo.org"]
    },

    celo_testnet: {
      chainId: `0x${Number(44787).toString(16)}`,
      chainName: "Celo (Alfajores Testnet)",
      nativeCurrency: {
        name: "Alfajores Celo",
        symbol: "A-CELO",
        decimals: 18
      },
      rpcUrls: [
        "https://alfajores-forno.celo-testnet.org"
      ],
      blockExplorerUrls: ["https://alfajores-blockscout.celo-testnet.org"]
    }

  };

const defaultNetworkObj = networks.Avalanche_Testnet; //set MetaMask_Onboarding's default network object
const defaultNetworkName = "Avalanche_Testnet"; //Rinkeby_Testnet or Avalanche_Testnet for now; !!!this sets contract files for Players.jsx


export { joinFee, sessionLength, networks, defaultNetworkObj, defaultNetworkName, chainIDs, networkNameMap, 
    ethTestEverestAddress, ethTestTokenAddress, 
    avaxTestTokenAddress, avaxTestEverestAddress,
    maticTestTokenAddress, maticTestEverestAddress,
    bscTestTokenAddress, bscTestEverestAddress,
    velasTestTokenAddress, velasTestEverestAddress,
    celoTestTokenAddress, celoTestEverestAddress,
    polygonTokenAddress, polygonEverestAddress,
    celoTokenAddress, celoEverestAddress,
}