import { React, useState} from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { joinFee,sessionLength, networks,networkNameMap,
		ethTestTokenAddress, ethTestEverestAddress, avaxTestTokenAddress, avaxTestEverestAddress,
		maticTestTokenAddress, maticTestEverestAddress, bscTestTokenAddress, bscTestEverestAddress, 
		velasTestTokenAddress, velasTestEverestAddress, celoTestTokenAddress, celoTestEverestAddress, 
		polygonTokenAddress, polygonEverestAddress, celoTokenAddress, celoEverestAddress,} from "./Constants.jsx";
import { ethers } from "ethers";
import tokenIcon from './image/2048token_192x192.png';
import { Tooltip, } from 'antd';

import { CopyToClipboard } from "react-copy-to-clipboard";


//import Web3Modal from "web3modal";

import Crypto2048Everest from './utils/Crypto2048Everest.json';

import PolygonCrypto2048Token from './utils/PolygonCrypto2048Token.json';
import CeloCrypto2048Token from './utils/CeloCrypto2048Token.json';


import TestCrypto2048Token from './utils/Crypto2048Token.json';
import AvaxTestCrypto2048Token from './utils/AvaxTestCrypto2048Token.json';
import MaticTestCrypto2048Token from './utils/MaticTestCrypto2048Token.json';
import BscTestCrypto2048Token from './utils/BscTestCrypto2048Token.json';
import VelasTestCrypto2048Token from './utils/VelasTestCrypto2048Token.json';
import CeloTestCrypto2048Token from './utils/CeloTestCrypto2048Token.json';

import { Modal } from 'antd';
import 'antd/dist/antd.min.css'

//to implement metamask onboarding for avax https://medium.com/coinmonks/create-an-avalanche-dapp-with-ethers-metamask-and-react-342d8d22cb30

const getNetworkName = async () => {
	let networkName;

	const { ethereum } = window; //matemask injected
	if (!ethereum) {
		console.log("no ethereum object");
	} else {
		//detect current connected network chainId
		await window.ethereum
		.request({ method: 'eth_chainId' })
		.then ( detectedChainId => {
			//console.log("Players: detectedChainId is ", detectedChainId);
			networkName = networkNameMap[detectedChainId];
			//console.log("Players: networkNameMap[detectedChainId] is ", networkName);
		}); 

		return networkName;
	}
}


const switchChainArtifacts = (networkName) => {

	let tokenAddress, everestAddress, tokenContractName, everestContractName;

	everestContractName = Crypto2048Everest;

	//if (defaultNetworkName === "Rinkeby_Testnet") {
	if (networkName === "Rinkeby_Testnet") {
	
		tokenAddress = ethTestTokenAddress;
		everestAddress = ethTestEverestAddress;

		tokenContractName = TestCrypto2048Token;

	} else if (networkName === "Avalanche_Testnet") {
		tokenAddress = avaxTestTokenAddress;
		everestAddress = avaxTestEverestAddress;

		tokenContractName = AvaxTestCrypto2048Token;

	} else if (networkName === "polygon") {
		tokenAddress = polygonTokenAddress;
		everestAddress = polygonEverestAddress;

		tokenContractName = PolygonCrypto2048Token;

	} else if (networkName === "polygon_testnet") {
		tokenAddress = maticTestTokenAddress;
		everestAddress = maticTestEverestAddress;

		tokenContractName = MaticTestCrypto2048Token;
	
	} else if (networkName === "bsc_testnet") {
		tokenAddress = bscTestTokenAddress;
		everestAddress = bscTestEverestAddress;

		tokenContractName = BscTestCrypto2048Token;

	} else if (networkName === "velas_testnet") {
		tokenAddress = velasTestTokenAddress;
		everestAddress = velasTestEverestAddress;

		tokenContractName = VelasTestCrypto2048Token;

	} else if (networkName === "celo") {
		tokenAddress = celoTokenAddress;
		everestAddress = celoEverestAddress;

		tokenContractName = CeloCrypto2048Token;

	} else if (networkName === "celo_testnet") {
		tokenAddress = celoTestTokenAddress;
		everestAddress = celoTestEverestAddress;

		tokenContractName = CeloTestCrypto2048Token;
		

	} else {
		return null;
	}

	return [tokenAddress,everestAddress,tokenContractName, everestContractName];

}

// Web3Modal and Provider Options; https://github.com/Web3Modal/web3modal
/* const providerOptions = {
	walletconnect: {
	  package: WalletConnectProvider, // required
	  options: {
		infuraId: "INFURA_ID" // required
	  }
	}
}; 

const web3Modal = new Web3Modal({
	network: "mainnet", // optional
	cacheProvider: true, // optional
	providerOptions // required
});  */


const levelMap = {0:"Sea Level", 1:"Foothill of Himalayas", 2:"Tourist Camp", 3:"Base Camp", 4: "Khumbu Icefall", 5:"Camp 1", 6:"Camp 2", 7:"Camp 3", 8:"Camp 4", 9:"Summit"};

const levelRewardRate = {0:1.0, 1:2.0, 2:3.0, 3:4.0, 4: 8.1, 5:16.1, 6:32.3, 7:64.5, 8:129.0, 9:258.0}; //rate is 2048 Tokens per saved session hour

const PlayersRankDesktop = (props) => {
		
	return (
		<tr className="border-b">
			<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{props.rank}</td>
			<td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">0x...{props.playerAddress.substr(props.playerAddress.length-10)}...
				<CopyToClipboard className="text-sm my-3 px-1 py-1 text-blue-900 bg-gray-200 w-fit rounded drop-shadow-sm hover:bg-sky-500"
					text={props.playerAddress}
					onCopy={() => alert("Copied")}>
					<span>   Copy</span>
				</CopyToClipboard>
			</td>
			<td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{ethers.utils.parseBytes32String(props.playerName)}</td>
			<td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{props.level}</td>
			<td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{props.bestScore}</td>
		</tr>
	);
	
};

const PlayersRankMobile = (props) => {
		
	return (
		<tr className="border-b">
			<td className="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{props.rank}</td>
			<td className="text-sm px-2 text-gray-900 font-light py-4 whitespace-nowrap">0x...{props.playerAddress.substr(props.playerAddress.length-10)}...
				<CopyToClipboard className="text-sm my-3 px-1 py-1 text-blue-900 bg-gray-200 w-fit rounded drop-shadow-sm hover:bg-sky-500"
					text={props.playerAddress}
					onCopy={() => alert("Copied")}>
					<span>   Copy</span>
				</CopyToClipboard>
			</td>
			<td className="text-sm px-2 text-gray-900 font-light py-4 whitespace-nowrap">{props.level}</td>
			<td className="text-sm px-2 text-gray-900 font-light py-4 whitespace-nowrap">{props.bestScore}</td>
		</tr>
	);
	
};

const PlayersRankDesktopAdmin = (props) => {
		
	return (
		<tr className="border-b">
			<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{props.rank}</td>
			<td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">0x...{props.playerAddress.substr(props.playerAddress.length-10)}</td>
			<td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{ethers.utils.parseBytes32String(props.playerName)}</td>
			<td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{props.level}</td>
			<td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{props.bestScore}</td>
			<td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{props.balanceOf2048Token}</td>
		</tr>
	);
	
};

const CurrentPlayer = (props) => {
	return (
		<div className="text-sm text-black-500 py-3 flex flex-col">
			<div>Player Name: {props.playerName}</div>
			<div>Level: {levelMap[props.level]} (Level {props.level})</div>
			<div>Token Reward Rate: {levelRewardRate[props.level]} {levelRewardRate[props.level]<=1?'token':'tokens'} per saved session hour</div>
			<div>Best Score: {props.bestScore}</div>
			<div>Registration Date: {props.joinedAt}</div>
			<div>Address: 0x...{props.playerAddress.substr(props.playerAddress.length-10)}</div>
			<div>Activated? {props.isActivated}</div>
		</div>
	);
};


const Registration = (props) => { //cannot use async for a component function; removing async here; 

	const [isModalVisible15, setIsModalVisible15] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [topPlayers, setTopPlayers] = useState([
		{playerAddress:"0x001", playerName:"x", bestScore:111, level:0, joinedAt:0, isActivated:false, balanceOf2048Token:0},
		{playerAddress:"0x002", playerName:"y", bestScore:333, level:0, joinedAt:0, isActivated:false, balanceOf2048Token:0}
	]);

	const showModal15 = async () => {
		setIsLoading(true);

		const players = await getAllPlayers();
		//console.log("allPlayers are: ", players);

		//sort by level first and then by bestScore; https://bobbyhadz.com/blog/react-sort-array-of-objects
		const sortedPlayers =[...players].sort((a, b) => (a.level.toNumber() > b.level.toNumber()) ? 1 : (a.level.toNumber() === b.level.toNumber()) ? ((a.bestScore.toNumber() > b.bestScore.toNumber()) ? 1 : -1) : -1 );

		//console.log ("players sorted by level and bestScore: ", sortedPlayers);

		const len = sortedPlayers.length;
		const size = Math.min(25, len); // only display top 25
		const topPlayersList = sortedPlayers.reverse().slice(0, size);

		//console.log ("top 25 players sorted by level and bestScore: ", topPlayersList);

		const formattedList = topPlayersList.map ( player => 
			({ //add parenthesis around the object as javascript interpret {} as code instead of object literal
				playerAddress: player.playerAddress, //if not exist, set it to ""
				playerName: player.playerName,
				bestScore: player.bestScore.toString(), 
				level: player.level.toNumber(),
			})
		);

		setTopPlayers(formattedList); 
		setIsModalVisible15(true);
		setIsLoading(false);
	};

	const handleCancel15 = () => {
		setIsModalVisible15(false);
	};


	
	//let networkName = "Avalanche_Testnet";
	//console.log("Registration component props.networkName is: ", props.networkName);

	return (
		<div className='text-xl5 mb-2 my-2 px-2 py-2 text-black-500 bg-gray-200 w-fit rounded drop-shadow-sm'>
			<div ><p className="text-red-500">Polygon network is unstable recently. After registration, if App still shows the "Please Register First" button, please refresh your browser after a few seconds. </p>You must have {networks[props.networkName].nativeCurrency.symbol} to play. Register with {joinFee} {networks[props.networkName].nativeCurrency.symbol} to join. Play to earn 2048 tokens. Reach tile 2048 (Base Camp) and even the ultimate tile 131072 (Summit) to earn rewards at higher rates!
				<div className='center-image my-3'>
					<img className="tokenImgSize" src={tokenIcon}/>

				</div>
				<div>
					<p>Player name must be less than 30 characters and not blank. If you don't have a referrer address, you can copy a top player's address from the 
						<button className="focus:outline-none text-sm mb-2 px-3 py-1
							text-black-500 bg-blue-200 w-fit rounded drop-shadow-sm hover:bg-sky-500"  
							onClick={showModal15}>Leaderboard
						</button> as the referrer address. Your referrer will earn additional 2048 tokens. You can also invite others to register using your ETH address as the referrer address to earn additional tokens. Mutual or self referral is not allowed. </p>
					<p>Your address is: 0x......{props.currentAccount.substr(props.currentAccount.length-10)} </p>
									
					<Modal title="Leaderboard" visible={isModalVisible15} onCancel={handleCancel15} footer={null}>
						{props.isLoading && ( <LoadingSpinner />)}
						<table className="min-w-full">
							<thead className="border-b">
								<tr>
									<th scope="col" className="text-sm font-medium text-gray-900 md:px-6 sm:px-2 py-4 text-left">Rank</th>
									<th scope="col" className="text-sm font-medium text-gray-900 md:px-6 sm:px-2 py-4 text-left">Address</th>
									<th scope="col" className="text-sm font-medium text-gray-900 md:px-6 sm:px-2 py-4 text-left">Level</th>
									<th scope="col" className="text-sm font-medium text-gray-900 md:px-6 sm:px-2 py-4 text-left">High Score</th>
								</tr>
							</thead>
							<tbody>											
								{topPlayers.map((player, i) => <PlayersRankMobile key={i} rank={i+1} playerAddress={player.playerAddress} level={player.level} bestScore={player.bestScore}/>)}
							</tbody>
						</table>
					</Modal>
					
					<input className="my-2 w-full" placeholder="Referrer's Address" onChange={e => props.setReferrerAddr(e.target.value)} />
					<Tooltip placement="topLeft" title="A referrer receives extra 2048 tokens (5% of the tokens earned by all his/her referrals) from the contract."><AiOutlineQuestionCircle /></Tooltip>
						
					<br/>

					<input className="my-2 w-full" placeholder="Player name" onChange={e => props.setUserName(e.target.value)} />

					<br/>
					<button className="focus:outline-none text-sm mb-2 px-3 py-1 text-black-500 bg-blue-200 w-fit rounded drop-shadow-sm hover:bg-sky-500" onClick={props.handleAddPlayerInput} disabled={props.isLoading}>Register</button>
				</div>
			</div>
		</div>

	);

}

const LoadingSpinner = () => {
	return (
	  <div className="spinner-container">
		<div className="loading-spinner">
		</div>
	  </div>
	);
}


const createContractObj = async () => {

	let networkName = await getNetworkName();
	//console.log("createContractObj's networkName is: ", networkName);
	
	const [tokenAddress,everestAddress,tokenContractName, everestContractName] = switchChainArtifacts(networkName);
	//console.log(`createContractObj's tokenAddress,everestAddress,tokenContractName, everestContractName are: ${tokenAddress}, ${everestAddress}, ${tokenContractName}, ${everestContractName}`)

	const { ethereum } = window;
	try {
	  	if (ethereum) {
			const provider = new ethers.providers.Web3Provider(ethereum);
			const signer = provider.getSigner();
			const Contract = new ethers.Contract(everestAddress, everestContractName.abi, signer);
			return Contract;
	  } else {
		console.log("Ethereum object doesn't exist!");
	  }
	} catch (error) {
	  console.log(error);
	}
}


const createTokenObj = async () => {
	let networkName = await getNetworkName();

	const [tokenAddress,everestAddress,tokenContractName, everestContractName] = switchChainArtifacts(networkName);

	const { ethereum } = window;
	try {
	  	if (ethereum) {
			const provider = new ethers.providers.Web3Provider(ethereum);
			const signer = provider.getSigner();
			const tokenContract = new ethers.Contract(tokenAddress, tokenContractName.abi, signer);
			return tokenContract;
	  } else {
		console.log("Ethereum object doesn't exist!");
	  }
	} catch (error) {
		console.log(error);
	}
}

const addPlayer = async (_userName, _addr) => {	
	const Contract = await createContractObj();		
			
	const options = {value: ethers.utils.parseEther(joinFee),
					gasLimit: 2000000 //optional
					} //use this option to add ETH value and gas limit while calling contract payable function

	const submitTxn = await Contract.addPlayer(_userName, _addr, options); //, 
	console.log("Adding a new player...", submitTxn.hash);

	await submitTxn.wait();
	console.log("added -- ", submitTxn.hash);	
}


const checkIfPlayerExist = async (_addr) => {
	const Contract = await createContractObj();	
	const result = await Contract.checkIfPlayerExist(_addr); //object array 
	return result;
}

const getAllPlayers = async () => {

	const Contract = await createContractObj();	
	const allPlayers = await Contract.getAllPlayers(); //object array 
	//console.log("getAllPlayers() allPlayers is:", allPlayers);
	return allPlayers;
}

/* const getCurrentPlayer = async (address) => {
	const Contract = await createContractObj();		

	const player = await Contract.getCurrentPlayer(address); //object array 
	return player;
} */

// leverage Player[] public playersList that has a public Getter function; so as to shrink Contract size
const getCurrentPlayer = async (address) => {
	const Contract = await createContractObj();		
	const playerIndex = await Contract.findPlayerIndex(address); //find index first
	//console.log("playerIndex is:", playerIndex)
	const player = await Contract.playersList(playerIndex); //public getter function array(index)
	//console.log("getCurrentPlayer() player is: ", player);
	return player;
}

// TypeError: Cannot read properties of undefined (reading 'map') at convertHexListToNumList (Grids.jsx:129:1)

/* const getCurrentSession = async (address) => {
	const Contract = await createContractObj();		

	const sessionIndex = await Contract.findSessionIndex(address); //find index first

	console.log("sessionIndex is:", sessionIndex);

	const gameSession = await Contract.gameSessions(sessionIndex);  //public getter function array(index)

	console.log("getCurrentSession() gameSession is: ", gameSession);

	return gameSession;
} */


const activatePlayer = async (_address) => {
	const Contract = await createContractObj();
	await Contract.activatePlayer(_address, { gasLimit: 1000000 });
}

const deactivatebyOwner = async (_address) => {
	createContractObj().then(value => { // value is the return contract object from createContractObj promise
		//console.log("value is :", value);
		value.deactivatebyOwner(_address, { gasLimit: 1000000 });
	})
}

const isAmbassador = async (_address) => {
	const Contract = await createContractObj();
	let result = await Contract.isAmbassador(_address);
	//console.log("hasRole result is: ", result);
	return result;
}

const hasCurrSession = async () => {
	const Contract = await createContractObj();
	const result = await Contract.hasCurrSession(); 
	return result;
} 

const extractErrText = (err) => {
	let arr = err.toString().split(/ reason= {0,1}/); //turn err promise to string and split by keyword "reason="
	let textWithQuote = arr[arr.length-1]; // extract everything after "reason=";
	const textWithoutQuote = textWithQuote // extract string between quotes; reason="......."
							.match(/(?:"[^"]*"|^[^"]*$)/)[0]
							.replace(/"/g, "")
	return textWithoutQuote;
}

const checkIfWalletIsConnected = async () => {
    try {
      const { ethereum } = window;
  
      if (!ethereum) { //no metamask injection
        console.log("Make sure you have MetaMask browser extension on your desktop or MetaMask App on mobile phone.");
        return;
      } else {
        console.log("We have the ethereum object", ethereum);
      }
  
      const accounts = await ethereum.request({ method: 'eth_accounts' });
  
      if (accounts.length !== 0) {
        const account = accounts[0];
        console.log("Found an authorized account:", account);
        return true;
      } else {
        console.log("No authorized account found")
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }

/* 
const payWithMetamask2 = async (_addr) => { // this works independently for sending specific amount of ETH
	const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
	// get a signer wallet!
	const signer = provider.getSigner();

	// Creating a transaction param
	const tx = {
		from: _addr,
		to: everestAddress,
		value: ethers.utils.parseEther(joinFee),
		nonce: await provider.getTransactionCount(_addr, "latest"),
		gasLimit: ethers.utils.hexlify(300000),
		gasPrice: ethers.utils.hexlify(parseInt(await provider.getGasPrice())),
	};

	signer.sendTransaction(tx).then((transaction) => {
		console.dir(transaction);
		alert("Sending is finished!");
	});
}
*/

export {
	PlayersRankMobile,
	PlayersRankDesktop,
	PlayersRankDesktopAdmin,
	CurrentPlayer,
	getAllPlayers,
	addPlayer,
	activatePlayer,
	getCurrentPlayer,
	deactivatebyOwner,
	createContractObj,	
	createTokenObj,
	LoadingSpinner,
	extractErrText,
	isAmbassador,
	hasCurrSession,
	checkIfWalletIsConnected,
	checkIfPlayerExist,
	Registration,
	levelRewardRate,
}