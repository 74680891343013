import { React  } from "react";

//main reference: https://betterprogramming.pub/building-a-simple-countdown-timer-with-react-4ca32763dda7
// Timer component removed and integrated into App due to issues, might need to avoid props as initial states; x
// Random component for countdown component
const Completionist = () => <span>Ended!</span>;

// Renderer callback with condition
//Handle single digit second display: https://betterprogramming.pub/building-a-simple-countdown-timer-with-react-4ca32763dda7
const renderer = ({ hours, minutes, seconds, completed }) => {
	if (completed) {
	  // Render a complete state
	  return <Completionist />;
	} else {
	  // Render a countdown
	  return (
		<span>
            { hours ===0 && minutes <= 3 // when only 3 minute left, make timer red
		        ?<h1 className="text-red-500">{hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h1>
                :<h1 className="text-black-900">{hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h1>
            }
		</span>
	  );
	}
  };

// a function to read data from local storage
const getLocalStorageValue = (s) => localStorage.getItem(s);

export {
    Completionist,
    renderer,
    getLocalStorageValue,

}