import { React, } from "react";
import ReactDOM from "react-dom";
import {Helmet} from "react-helmet";
import App from "./App";
import "./index.css"
//Photo (bg-laptop2048bg bg-mobile2048bg) by Parth Savani on Unsplash
//disable react-dev-tools for this project in production mode!

if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
	for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
		window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? ()=>{} : null;
	}
}

ReactDOM.render(

	<div className="flex flex-nowrap justify-center py-5 bg-cover bg-scroll bg-center min-h-screen md:bg-laptop2048bg bg-mobile2048bg" >
		<Helmet>
			<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
  		</Helmet>
		<App />
	</div>,
	document.getElementById('root')
); 