import { React, useState, useEffect, useRef, } from "react";
//import ChainContextProvider from './context/ChainContext';
//import ChainContext from './context/ChainContext';
import { sessionLength, networkNameMap, networks} from "./Constants.jsx";
import { OnboardingButton, isAmongNetworks, ChangeNetwork,} from './MetaMask_Onboarding';
import { Tile, NewTile,} from "./Tile";
import { PlayersRankDesktop, PlayersRankMobile, PlayersRankDesktopAdmin, Registration,CurrentPlayer, LoadingSpinner, addPlayer, activatePlayer, deactivatebyOwner,getCurrentPlayer, 
		createContractObj,createTokenObj, isAmbassador, hasCurrSession, 
		checkIfPlayerExist, levelRewardRate, getAllPlayers,
		} from "./Players";
import {
		makeGrid, moveTiles, isWon, isGameOver, isGridsEqual, isFull,
		pushUp, pushDown, pushLeft, pushRight, generateNewTile, rotateCW, deepCopy, gridTo1DArray,
		calRowScore, flatArrayTo2D,convertHexListToNumList,
		} from './Grids'
import { renderer,} from './Timer'
import useKeypress from "react-use-keypress";
import { useSwipeable } from "react-swipeable";
import { useMediaQuery } from 'react-responsive';
import useSound from 'use-sound';
import slide from './sound/slide.mp3';
//import soundToggle from './image/soundToggle.jpg';
import { ethers } from "ethers";

import {format, intervalToDuration} from 'date-fns';
import { Tooltip, Switch, Modal, Select, } from 'antd';
import 'antd/dist/antd.min.css'
import { AiFillQuestionCircle } from "react-icons/ai";

import Countdown from "react-countdown"; //https://dev.to/xampy/react-js-countdown-timer-restart-when-page-os-reloaded-simple-solution-3m2f


//https://css-tricks.com/dealing-with-stale-props-and-states-in-reacts-functional-components/
function useAsyncReference(value, isProp = false) {
	const ref = useRef(value);
	const [, forceRender] = useState(false);
	
	function updateState(newState) {
		ref.current = newState;
		forceRender(s => !s);
	}

	if (isProp) {
		ref.current = value;
		return ref;
	}
	return [ref, updateState];
}


const App = () => {

	const [grid, setGrid] = useState(makeGrid(4));
	const [lastGrid, setLastGrid] = useState(grid);
	const [movedGrid, setMovedGrid] = useState(grid);
	const [score, setScore] = useState(0);
	const [won, setWon] = useState(false);
	const [lost, setLost] = useState(false);
	const [reach512, setReach512] = useState(false);
	const [reach1024, setReach1024] = useState(false);
	const [reach2048, setReach2048] = useState(false);
	const [reach4096, setReach4096] = useState(false);
	const [reach8192, setReach8192] = useState(false);
	const [reach16384, setReach16384] = useState(false);
	const [reach32768, setReach32768] = useState(false);
	const [reach65536, setReach65536] = useState(false);

	const [size, setSize] = useState(4);
	const [newTilePos, setNewTilePos] = useState(0);

	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1025px)'})
	const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1025px)' })
	const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
	const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

//////bockchain2048 start1
	const [currentAccount, setCurrentAccount] = useState(""); //only non-checksum address from metamask
	const [userName, setUserName] = useState(""); //for registration only
	const [ifPlayerExist, setIfPlayerExist] = useState(false);
	const [currentPlayer, setCurrentPlayer] = useState({playerAddress:"", playerName:"", bestScore:0, level:0, joinedAt:0, isActivated:false, rewardBal:0}); //Player struct / object
	
	const [topPlayers, setTopPlayers] = useState([
		{playerAddress:"0x001", playerName:"x", bestScore:111, level:0, joinedAt:0, isActivated:false, balanceOf2048Token:0},
		{playerAddress:"0x002", playerName:"y", bestScore:333, level:0, joinedAt:0, isActivated:false, balanceOf2048Token:0}
	]);

	const [address, setAddress] = useState("");
	const [referrerAddr, setReferrerAddr] = useState("");
	const [ifContractGridLoaded, setIfContractGridLoaded] = useState(false);
	const [hasCurrentSession, setHasCurrentSession] = useState(false);
	
	const [sessionTimeLeft, setSessionTimeLeft] = useAsyncReference({hours:0, minutes:0, seconds:0});
	const [networkName, setNetworkName] = useAsyncReference("");
	const [chainId, setChainId] = useAsyncReference("");


	//const ref = useRef(sessionTimeLeft); //https://css-tricks.com/dealing-with-stale-props-and-states-in-reacts-functional-components/

	const [contractEthBalance, setContractEthBalance] = useState(0);
	const [playerCMGBalance, setPlayerCMGBalance] = useState(0);
	const [rewardBalance, setRewardBalance] = useState(0);
	const [ambasRewardBalance, setAmbasRewardBalance] = useState(0);
	const [numMove, setNumMove] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isAmbas, setIsAmbas] = useState(false);
	const [isOnRightChain, setIsOnRightChain] = useState(false);
	const [isOffChainMode, setIsOffChainMode] = useState(localStorage.getItem('isPracticeMode') === "true");
	const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible3, setIsModalVisible3] = useState(false);
	const [isModalVisible4, setIsModalVisible4] = useState(false);
	const [isModalVisible5, setIsModalVisible5] = useState(false);
	const [isModalVisible6, setIsModalVisible6] = useState(false);

	const [isModalVisible7, setIsModalVisible7] = useState(false);
	const [isModalVisible8, setIsModalVisible8] = useState(false);
	const [isModalVisible9, setIsModalVisible9] = useState(false);
	const [isModalVisible10, setIsModalVisible10] = useState(false);
	const [isModalVisible11, setIsModalVisible11] = useState(false);
	const [isModalVisible12, setIsModalVisible12] = useState(false);
	const [isModalVisible13, setIsModalVisible13] = useState(false);

	const [isModalVisible14, setIsModalVisible14] = useState(false);
    
/////Timer
	const [data, setData] = useState(
		{ date: Date.now(), delay: sessionLength*1000 } //in milliscond
	);
	const wantedDelay = sessionLength*1000; //in milliscond

  //[START] componentDidMount
  //Code runs only one time after each reloading
	useEffect(() => {

		//console.log("is useEffect working?");
		//if (ifPlayerExist){  // this will cause Timer to reset to 4 hour upon every refresh
        //if(hasCurrentSession){  //might be problematic? as hasCurrentSession might not be set on first rendering?
            getCurrentSessionTime().then (endTime => {

				if (endTime != null) {

					const formatEndTime = format (new Date(endTime), 'kk:mm:ss'); // endTime is UNIX timestamp in millisecond

					//console.log("Timer useEffect: endTime is:", formatEndTime);

					if (endTime != null && !isNaN(endTime)) {
						const currentTime = Date.now(); //The static Date.now() method returns the number of milliseconds elapsed since January 1, 1970 00:00:00 UTC.
						//const delta = parseInt(savedDate, 10) - currentTime;  //localStorage version
						const delta = endTime - currentTime;

						//console.log("Timer useEffect: savedDate - currentTime: ", format (new Date(delta), 'kk:mm:ss'));

						//Do you reach the end?
						if (delta <=0 ) {
							//Yes, we clear our saved end date in localStorage
						} else {
							//No, update the end date  
							setData({ date: currentTime, delay: delta }); //setSate to update object
							let formatCurrentTime = format(new Date(currentTime), 'kk:mm:ss');
							let formatDelta = format(new Date(delta), 'kk:mm:ss');
							//console.log (`Timer useEffect currentTime is ${formatCurrentTime} and delta is ${formatDelta}`);

						}
					}
				}
			})
		//}
        //}
	}, []); // only update upon the first render
	//[END] componentDidMount

//////Timer Ends

	const showModal = () => {
		setIsModalVisible(true);
	  };
	
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModal3 = () => {
		setIsModalVisible3(true);
	  };
	
    const handleOk3 = () => {
        setIsModalVisible3(false);
    };

    const handleCancel3 = () => {
        setIsModalVisible3(false);
    };

	const showModal4 = () => {
		setIsModalVisible4(true);
	  };
	
    const handleOk4 = () => {
        setIsModalVisible4(false);
    };

    const handleCancel4 = () => {
        setIsModalVisible4(false);
    };

	const showModal5 = () => {
		setIsModalVisible5(true);
	  };
	
    const handleOk5 = () => {
        setIsModalVisible5(false);
    };

    const handleCancel5 = () => {
        setIsModalVisible5(false);
    };

	const showModal6 = () => {
		setIsModalVisible6(true);
	  };

	const handleCancel6 = () => {
        setIsModalVisible6(false);
    };

	const showModal7 = () => {
	setIsModalVisible7(true);
	};

	const handleCancel7 = () => {
        setIsModalVisible7(false);
    };

	const showModal8 = () => {
		setIsModalVisible8(true);
	};

	const handleCancel8 = () => {
        setIsModalVisible8(false);
    };

	const showModal9 = () => {
		setIsModalVisible9(true);
	};

	const handleCancel9 = () => {
        setIsModalVisible9(false);
    };

	const showModal10 = () => {
		setIsModalVisible10(true);
	};

	const handleCancel10 = () => {
        setIsModalVisible10(false);
    };

	const showModal11 = () => {
		setIsModalVisible11(true);
	};

	const handleCancel11 = () => {
        setIsModalVisible11(false);
    };

	const showModal12 = () => {
		setIsModalVisible12(true);
	};

	const handleCancel12 = () => {
        setIsModalVisible12(false);
    };

	const showModal13 = () => {
		setIsModalVisible13(true);
	};

	const handleCancel13 = () => {
        setIsModalVisible13(false);
    };
	
	const showModal14 = async () => { // PlayersRank modal component trigger

		setIsLoading(true);

		const players = await getAllPlayers();
		//console.log("allPlayers are: ", players);

		//sort by level first and then by bestScore; https://bobbyhadz.com/blog/react-sort-array-of-objects
		const sortedPlayers =[...players].sort((a, b) => (a.level.toNumber() > b.level.toNumber()) ? 1 : (a.level.toNumber() === b.level.toNumber()) ? ((a.bestScore.toNumber() > b.bestScore.toNumber()) ? 1 : -1) : -1 );

		//console.log ("players sorted by level and bestScore: ", sortedPlayers);

		const len = sortedPlayers.length;
		const size = Math.min(25, len); // only display top 25
		const topPlayersList = sortedPlayers.reverse().slice(0, size);

		//console.log ("top 25 players sorted by level and bestScore: ", topPlayersList);

		const formattedList = topPlayersList.map ( player => 
			({ //add parenthesis around the object as javascript interpret {} as code instead of object literal
				playerAddress: player.playerAddress, //if not exist, set it to ""
				playerName: player.playerName,
				bestScore: player.bestScore.toString(), 
				level: player.level.toNumber(),
			})
		);

		setTopPlayers(formattedList); 

		//console.log(formattedList);
	
		
		//setTopPlayers((prevTopPlayers) => [topPlayersList, ...prevTopPlayers]); //https://www.techiediaries.com/react-18-usestate-array/; we need to return a new state based on the current state and the new value. To do this, the setter function accepts a callback function that takes the current state as argument:
		
		/* setTopPlayers((prevTopPlayers) => [
			topPlayersList,
			...prevTopPlayers
		
		]); //https://www.techiediaries.com/react-18-usestate-array/; we need to return a new state based on the current state and the new value. To do this, the setter function accepts a callback function that takes the current state as argument:

		*/

		 // https://dev.to/joelynn/react-hooks-working-with-state-arrays-2n2g; it's important to not mutate state directly, so here we are creating a copy of the current state using the spread syntax
		/* const updateTopPlayers = [
			// copy the current users state
			...topPlayers,
			// now you can add a new object to add to the array
			{
			  // using the length of the array for a unique id
			  playerAddress: users.length + 1,
			  // adding a new user name
			  name: "Steve",
			  // with a type of member
			  type: "member"
			}
		  ];
		  // update the state to the updatedUsers
		  setUsers(updateUsers);
		  */


		setIsModalVisible14(true);

		setIsLoading(false);

	};

	const handleCancel14 = () => {
        setIsModalVisible14(false);
    };

    const { Option } = Select;
	/* if(localStorage.getItem("isPracticeMode") != null){
		let str = localStorage.getItem("isPracticeMode");
		let boolResult = (str === 'true');
		setIsOffChainMode(boolResult);
		console.log("boolResult is:", boolResult);
	} */

	const disablePracticeMode = () => {
		localStorage.setItem("isPracticeMode", false);
		setIsOffChainMode(false);
	}

	const handleAddPlayerInput = async () => {
		// need to set practice mode to off before "setIsLoading(true)" disables the Switch
		disablePracticeMode();
		
		setIsLoading(true);
		//console.log ("Adding player name....: ", userName);
		
		try{

			const addTxn = await addPlayer(userName, referrerAddr);
			await connectWallet();
			setCurrentPlayer({
				playerAddress: currentAccount, 
				playerName: userName, 
				bestScore: 0, 
				level: 0, 
				joinedAt: format(new Date(), 'MMM dd, yyyy'), //new Date() is an object and cannot be directly passed to child component via props; need to convert to string first;
				isActivated: "Yes",
				rewardBal: 0,
			});

			await getCurrentSessionTime(); // this hasCurrentSession to true and show session time on the top to player
			setIsLoading(false);

			handleCancel(); //cancel the Registration modal after completion

			alert(`You are registered! Your first ${sessionLength/60}-minute session starts now. You must save your board to the blockchain within the session using the Save button. Otherwise, your progress won't be recorded.`);


		}catch(err){
			await sleep(10000); //allow Polygon to settle for 5s; temporarily solve Polygon RPC error after adding a player
			window.location.reload(); //temporarily solve Polygon RPC error after adding a player 
			console.log(err);
			setIsLoading(false);

			handleCancel();

		}
	};

	const sleep = ms => new Promise(r => setTimeout(r, ms));
	
	//admin only
	const getBalanceOfCMG = async (_address) => {
		const Contract = await createTokenObj();
		let balance = await Contract.balanceOf(_address); //this is hex token balance, bigNumber; use ethers.utils.formatEther to convert to numbers;
		balance = ethers.utils.formatEther(balance)
		setPlayerCMGBalance(balance);
	}

	//admin only
	const deletePlayers = async () => {
		const Contract = await createContractObj();
		await Contract.deletePlayers();
		//console.log("all players are deleted");
	}

	const getRewardBalance = async (_address) => {
		const Contract = await createContractObj();
		let balance = await Contract.rewardBalance(_address); //via public mapping getter; this is hex token balance, bigNumber; use ethers.utils.formatEther to convert to numbers;
		balance = ethers.utils.formatEther(balance)
		setRewardBalance(parseFloat(balance)); //must be in Number; otherwise, the Claim Reward button cannot be disabled if rewardBalance is in string format after claiming
	}
	
	// transfer from contract to msg.sender
	const claimReward = async () => {
		setIsLoading(true);
		try { 
			const Contract = await createContractObj();
			const claimTxn = await Contract.claimReward({gasLimit: 2000000}); 
			console.log("Claiming rewards...please wait");
			await claimTxn.wait();
			await getRewardBalance(currentAccount);
			await getBalanceOfCMG(currentAccount);
			setRewardBalance(0);

			setIsLoading(false);

		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
	}

	const getAmbasRewardBalance = async () => {
		const Contract = await createContractObj();
		let balance = await Contract.ambasRewardBalance(currentAccount); //public getter for mapping; this is hex token balance, bigNumber; use ethers.utils.formatEther to convert to numbers;
		balance = ethers.utils.formatEther(balance)
		setAmbasRewardBalance(parseFloat(balance)); //must be in Number; otherwise, the Claim Reward button cannot be disabled if rewardBalance is in string format after claiming
	}


	// transfer from contract to msg.sender
	const ambasClaimReward = async () => {
		setIsLoading(true);
		try { 
			const Contract = await createContractObj();
			const claimTxn = await Contract.ambasClaimReward({gasLimit: 2000000}); 
			console.log("Claiming rewards...please wait");
			await claimTxn.wait();
			await getAmbasRewardBalance(currentAccount);
			await getBalanceOfCMG(currentAccount);
			setAmbasRewardBalance(0);

			setIsLoading(false);

		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
	}

	const startSession = async () => {
		//turn off practice mode first before "setIsLoading(true)" disables the Switch
		disablePracticeMode();
		
		setIsLoading(true);

		//localStorage.removeItem("sessionEndTime"); //remove the outdated value; not using localStorage to calculate duration; instead, use getCurrentSession from blockchain

		try{
			const Contract = await createContractObj();
			const sessionTxn = await Contract.startSession({ gasLimit: 2000000 });

			console.log("Starting a new session...", sessionTxn.hash);
			await sessionTxn.wait();
			console.log("Started -- ", sessionTxn.hash);

			setNumMove(0); //reset numMove to 0 every time the board is loaded from blockchain for a new session
			// localStorage could cause inaccurate NumMove measurement, resulting in false fraud detection; 

			/* const onNewSession = (from, timestamp, endGrid, confirmedScore) => {
				setGrid(flatArrayTo2D(convertHexListToNumList(endGrid),4)); //set Grid to last session endGrid
				setScore(confirmedScore.toNumber()); //set Score to last session confirmedScore; confirmedScore is uint256 bigNumber, must convert to numbers
				setIfContractGridLoaded(true);	
				let timestampStart = format(new Date(timestamp * 1000), 'kk:mm:ss'); //new Date() argument is in millisecond; timestamp from blockchain is Unix time in second. 
				let timestampEnd = new Date(timestamp *1000 + sessionLength * 1000);
		
				console.log("startSession onNewSession endGrid is", flatArrayTo2D(convertHexListToNumList(endGrid),4));
				console.log("startSession onNewSession formatted timeStampStart is:", timestampStart);


				//setSessionStartTime(timestampStart); 
				//setSessionEndTime(timestampEnd);
			};
		
			Contract.on("SessionStarted", onNewSession); */

			setHasCurrentSession(true);
			
			//await getCurrentSessionGrid(); //onNewSession event is slow...using this one instead

			setIsLoading(false);

			//remove onNewSession section as reload() is retrieving last session endGrid;
			window.location.reload(); //to reset Timer, otherwise Timer will show "Ended" as useEffect is not running without reloading	

			/* return () => {
			if (Contract) {
				Contract.off("SessionStarted", onNewSession);	
				}
			} */
		} catch (err) {
			console.log(err);
			setIsLoading(false);
			window.location.reload(); //to reset Timer, otherwise will show "Ended"
		}
	}


	const passTimerProps = async () => {

		let currSession = await hasCurrSession();

		if (currSession){
			let endTime = await getCurrentSessionTime();
			let formatEndTime = format(new Date (endTime *1000), 'kk:mm:ss');
			//console.log("passTimerProps formatEndTime: ", formatEndTime);

			let now = Date.now(); //Unix timestamp in millisecond from 1970, Jan 1.

			if(now < endTime){
				let duration = intervalToDuration({ //https://date-fns.org/v2.28.0/docs/intervalToDuration
				start: now,
				end: endTime
				});

				setSessionTimeLeft({
					hours: duration.hours,
					minutes: duration.minutes,
					seconds: duration.seconds,
				});

				//ref.current = duration;
				//setSessionEndTime(format((sessionEndTime), 'kk:mm:ss'));
				//console.log("useAsyncReference sessionTimeLeft.current is: ", sessionTimeLeft.current);
				let timestampEnd = format(endTime, 'kk:mm:ss');

				//console.log("Formatted End Time is: ", timestampEnd);
				//console.log("sessionTimeLeft.current.minutes is: ", sessionTimeLeft.current.minutes);
				return duration;
			}	
		}
	}

	//need to setHasCurrentSession to false during this if current session expired
	const getCurrentSessionGrid = async () => { //in case a player refresh the page, this allows players to reload the grid during the current session as they cannot reload using Load Grid from Block button during a session
		setIsLoading(true);

		const currSession = await hasCurrSession();

		if (currSession){
			const Contract = await createContractObj();
			const sessionTxn = await Contract.getCurrentSession(); 
			console.log("Retrieving current session board...");
			setGrid(flatArrayTo2D(convertHexListToNumList(sessionTxn.endGrid),4)); // not sure if this works!!!! to test
			setScore(sessionTxn.confirmedScore.toNumber());

			//console.log("getCurrentSessinoGrid grid is:", flatArrayTo2D(convertHexListToNumList(sessionTxn.endGrid),4));

			console.log("Done");

			await getRewardBalance(currentAccount);
			await getBalanceOfCMG(currentAccount);

			//await getCurrentSessionTime(); no longer need to display session end time as we will show countdown only
			setHasCurrentSession(true);
			await displayCurrentPlayer(currentAccount);

			await passTimerProps();

			setIsLoading(false);
		} else {
			alert("Error: no current session"); //only work for view or getter
			
			setHasCurrentSession(false);
			setIsLoading(false);
		} 
	}


	const getCurrentSessionTime = async () => {

		let currSession = await hasCurrSession();

		if (currSession){
			const Contract = await createContractObj();
			const sessionTxn = await Contract.getCurrentSession(); 
			console.log("Retrieving current session time...");
			//let startTime = new Date(sessionTxn.sessionStartAt * 1000);
			//let endTime = new Date(sessionTxn.sessionStartAt * 1000 + sessionLength * 1000);

			let endTime = sessionTxn.sessionStartAt * 1000 + sessionLength * 1000;
			let formatEndTime = format(new Date (endTime), 'kk:mm:ss');

			//console.log("getCurrentSessionTime formatted endTime is: ", formatEndTime);

			//setSessionStartTime(startTime);
			//setSessionEndTime(endTime);
			console.log("Done");

			setHasCurrentSession(true);
			return endTime;
		} else {
			setHasCurrentSession(false);
			return null;
		}
	}

	
	//////////////

	const submit = async () => {
		setIsLoading(true);
		
		try{
			const Contract = await createContractObj();
			const submitTxn = await Contract.submit(score, gridTo1DArray(grid), numMove, { gasLimit: 2000000 });

			console.log("Mining...", submitTxn.hash);
			await submitTxn.wait();
			console.log("Mined -- ", submitTxn.hash);
			//after save to board, the total reward balance should be updated so as to update available CMG to claim; 

			await displayCurrentPlayer(currentAccount); // refresh scores etc. right after a submission
			await getRewardBalance(currentAccount);
			await getAmbasRewardBalance(currentAccount);
			await getBalanceOfCMG(currentAccount);

			setIsLoading(false);
		} catch(err){
			console.log(err);
			setIsLoading(false);
		}
	}
	  
	//////upgradeable2048 end1

    //const accountPromisetoValue ; the following worked; but could be triggered on every render
	/*connectWallet().then( value => {	// convert promise to value for currentAccount state (metamask address non-checksum)	
		setCurrentAccount(value);
		console.log("current account is: ", currentAccount);
	}); */

	const updateIfPlayerExists = async (_addr) => {
		let result = await checkIfPlayerExist(_addr);
		//console.log("checkIfPlayerExist result is: ", result);
		setIfPlayerExist(result);
		return result;
	}

    //important; determine if user is on a supported network; if so, show Registration, etc.; otherwise, show Onboarding's Select Blockchain Network button
	const handleOnboardingCallback = (currentAccts, chainId, isRightChain) => {
        
		setCurrentAccount(currentAccts[0]); //important to set this as connectWallet might not set this in time during Registration

		//console.log("handleOnboardingCallback isRightChain is:", isRightChain);
		//console.log("handleOnboardingCallback currentAccts[0] is:", currentAccts[0]);
		//console.log("handleOnboardingCallback chainId is:", chainId);
		//console.log("handleOnboardingCallback networkName is:", networkNameMap[chainId]);
		//console.log("handleOnboardingCallback networkName.current is:", networkName.current);

		setNetworkName (networkNameMap[chainId]);
		setChainId (chainId.toLowerCase());

		//console.log("handleOnboardingCallback chainId is", chainId);

		if (isRightChain){
			setIsOnRightChain(true);
			connectWallet(); // issue: this will bypass network check once account is connected!; solved with if isRightChain
		}
    }

	const connectWallet = async () => {
		setIsLoading(true);
		//console.log(localStorage.getItem("grid"));

		try {
			const { ethereum } = window;
			if (!ethereum) {
			  return;
			}
			const accounts = await ethereum.request({ method: "eth_requestAccounts" });
			console.log("currentAccount Connected is", accounts[0]);
			//console.log(window.ethereum.networkVersion, 'window.ethereum.networkVersion');
			
			setCurrentAccount(accounts[0]);  //convert account to ethereum checksum format so as to be compatible with address data feteched from contract
			
            if (isAmongNetworks()){
                setIsOnRightChain(true);
            }

			if (isOffChainMode){
				console.log(`isOffChainMode is ${isOffChainMode}`);
				//localStorage.setItem converted 2D array to string! need to convert it back 2D Number array
				if(localStorage.getItem('grid')){ //if grid is null, it can cause error
					let arrOfStr = localStorage.getItem('grid').split(',');
					//console.log("arrOfStr is: ", arrOfStr);
					let arrOfNum = []; // should not use const
					arrOfStr.forEach(str => {
						arrOfNum.push(Number(str));
					});
					//console.log("localstorage.getItem 2D array is", setGrid(flatArrayTo2D(arrOfNum, 4)));
					//setGrid(flatArrayTo2D(arrOfNum, 4));
					setGrid(flatArrayTo2D(arrOfNum, 4));
					//setGrid([[8, 8, 0, 0],[8, 2, 0, 2],[0, 0, 0, 0],[0, 0, 0, 0]]);
				}

				if(localStorage.getItem('score')){ //if score is null, it can cause error
					setScore(Number(localStorage.getItem('score')));
				}

				if(localStorage.getItem('numMove')){ //if score is null, it can cause error
					setNumMove(Number(localStorage.getItem('numMove')));
				}
			}
			
			const isAmbasOrNot = await isAmbassador(accounts[0]);
			if(isAmbasOrNot){
				//console.log("isAmbassdor: ", isAmbasOrNot);
				setIsAmbas(true);
				//console.log("setIsAbmas", isAmbas);
			}

			const result = await updateIfPlayerExists(accounts[0]);
			console.log("ifPlayerExist value is: ", result);
			
			if(result) {
				//console.log("current account exists in Contract playersList");
				
				await displayCurrentPlayer(accounts[0]); //should not use currentAccount here; otherwise will product ENS name error
				await getBalanceOfCMG(accounts[0]);
				await getRewardBalance(accounts[0]);

				const currSession = await hasCurrSession();
			
				// this hasCurrentSession to true and show session time on the top to player; if no current session, hasCurrentSession will be false (not exist)
				if(currSession){
					console.log("hasCurrSession() value is ", currSession)
					setHasCurrentSession(true);
					//await getCurrentSessionTime();

					await getCurrentSessionGrid();

				} else {
					setHasCurrentSession(false);
				}				
			}

			setIsLoading(false);
	
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}	
	} 

	const practiceModeSwitch = (checked) => {
		console.log(`switch to ${checked}`);
		if(checked){
			setIsOffChainMode(true);
			localStorage.setItem("isPracticeMode", true);
		} else {
			setIsOffChainMode(false);
			localStorage.setItem("isPracticeMode", false);
		}
	};
	

	//once detected currentAccount, show player status if player.playerAddress is not ""; 
	const displayCurrentPlayer = async (_addr) => {
		setIsLoading(true);

		const player = await getCurrentPlayer(_addr); //await the async function so that it doesn't return a promise type
		//console.log("Player is: ", player); 
		//const isEmpty = Object.keys(player).length === 0; //check if player object is empty or not
			if (typeof player !== 'undefined') { //if object exists, return true	
				setCurrentPlayer({
					playerAddress: player.playerAddress? player.playerAddress:"", //if not exist, set it to ""
					playerName: ethers.utils.parseBytes32String(player.playerName), 
					bestScore: player.bestScore.toString(), 
					level: player.level.toNumber(), 
					joinedAt: format(new Date(player.joinedAt * 1000), 'MMM dd, yyyy'), //new Date() is an object and cannot be directly passed to child component via props; need to convert to string first;
					isActivated: player.isActivated? "Yes": "No",
					rewardBal: player.rewardBal,
				});

				//update reward and wallet 2048 token balance
				await getRewardBalance(_addr);
				await getBalanceOfCMG(_addr);

				setIsLoading(false);

			} else {
				setIsLoading(false);
		}
	}

	/*
	useEffect(() => { 
		checkIfPlayerExist(currentAccount).then( value => {
			console.log("checkIfPlayerExist is: ", value);
			if (value) {
				setIfPlayerExist (true);

				if (currentAccount){
					console.log("current account exists in Contract playersList");
					displayCurrentPlayer(currentAccount);
				}; 
			}

		});
		
		
	}, [currentAccount])
	*/


	//No longer needed as we have MetaMask onboarding component
	/* useEffect(() => {
		checkIfWalletIsConnected().then (value => {

			console.log("checkifwalletisconnected is :" , value);

			if (!value){
				alert("Please Connect Ethereum Wallet First!");
			}
		});
		

	}, []); //only executed once after the first render and skipped for the following render cycles; if no array dependency, useEffect is called after every render
	*/

//////upgradeable2048 end1
	const moveGrid = moveFunc => { //called by moveGrid(pushLeft, etc...)
		const movedGrid = moveFunc(grid); //movedGrid is one without the newly generated tile
		
		if (!isGridsEqual(grid, movedGrid)) {
			setLastGrid(grid);
			let newGrid = moveTiles(grid, moveFunc); //newGrid is the one with the newly generated tile
			const maxTile = grid.reduce((a, r) => Math.max(a, r.reduce((c, d) => Math.max(c, d), 0)), 0);
			const maxTileOfNewGrid = newGrid.reduce((a, r) => Math.max(a, r.reduce((c, d) => Math.max(c, d), 0)), 0);
			const levels = [512, 1024, 2048, 4096, 8192, 16384, 32768, 65536, 131072];

			if (maxTile < 512 && maxTileOfNewGrid == 512 ) { //512
				setReach512(true);
				showModal6();
			} else if (maxTile < 1024 && maxTileOfNewGrid ==1024) { //1024
				setReach1024(true);
				showModal7();
			} else if (maxTile < 2048 && maxTileOfNewGrid ==2048) { //2048
				setReach2048(true);
				showModal8();
			} else if (maxTile < 4096 && maxTileOfNewGrid ==4096) {
				setReach4096(true);
				showModal9();
			} else if (maxTile < 8192 && maxTileOfNewGrid ==8192) {
				setReach8192(true);
				showModal10();
			} else if (maxTile < 16384 && maxTileOfNewGrid ==16384) {
				setReach16384(true);
				showModal11();
			} else if (maxTile < 32768 && maxTileOfNewGrid ==32768) {
				setReach32768(true);
				showModal12();
			} else if (maxTile < 65536 && maxTileOfNewGrid ==65536) {
				setReach65536(true);
				showModal13();
			} 

			//levels.forEach(lv => { maxTile >=  lv && !isFull(newGrid) && (newGrid = generateNewTile(newGrid)); }); // This will generate an additional tile when maxTile >= lv and newGrid is not full; 
			setGrid(newGrid);

			//save grid to localstorage
			localStorage.setItem("grid", newGrid);
			//console.log("newgrid to localstorage is:", newGrid);

			setMovedGrid(movedGrid);

			setWon(isWon(newGrid));		
			if (isWon(newGrid)) { showModal4()};

			setLost(isGameOver(newGrid));
			if (isGameOver(newGrid)) { showModal5()};

			//find the new tile position after flat()
			const findNewTilePos = (newG, movedG) => {		
				let temp = 0; 	
				newG.flat().forEach ((c, index) => {		
						if (c !== movedG.flat()[index]) { 
							temp = index; 
						} 
					});
				return temp;
			} 
		
			setNewTilePos(findNewTilePos(newGrid, movedGrid));
		
			const calPushScore = (grid) => {
				let pushLeftScoreArray = new Array();
				//pushLeftScoreArray = grid.reduce((acc, row) => acc + calRowScore(row), [0,0,0,0]); //4 row arrays reduced to 1 score array; put 4 rows' score into an array(4)
				pushLeftScoreArray = grid.map(row => calRowScore(row)); //4 row arrays reduced to 1 score array; put 4 rows' score into an array(4)

				//console.log ('pushLeftScoreArray is: ', pushLeftScoreArray);
				//next step; add scores in the array
				let allRowScore = pushLeftScoreArray.reduce((a,v) => a + v, 0 ); // sum up all 4 row scores for this move
				//console.log ('allRowScore is: ', allRowScore);
				localStorage.setItem("score", score + allRowScore); // do it before setScore, otherwise score can change

				setScore (score + allRowScore);

			}
		
			if (moveFunc === pushLeft || moveFunc === pushRight) { //left and right can both use the calPushScore (left) function to add score;
				calPushScore(grid);
			}
			if (moveFunc === pushUp || moveFunc === pushDown ) { //After rotateCW and deepCopy, up and down can both use the calPushScore (left) function to add score;
				calPushScore(rotateCW(deepCopy(grid)));
			}
		}
	}

	const [play] = useSound(slide); // this useSound hook; {play} can be only used in a functional (not class) component's top level

	useEffect(() => {
		//setScore(grid.reduce((a, r) => a + r.reduce((b, v) => b + v), 0)); //this scoring is not correct. 
	play(); //play slide sound via useSound hook
	}, [grid]);

	useEffect(() => {
		setGrid(makeGrid(size));
		setLost(false);
		setWon(false);
	}, [size])

	useKeypress([ // React hook which listens for pressed keys. useKeypress (expected keys, handler)
		"ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown",
		"/",
	], e => { //https://www.npmjs.com/package/react-use-keypress
		e.preventDefault();
		const checkKey = (expected, func) => [].concat(expected).some(k => k === e.key) && func(); //evaluate if e.key (user pressed) is in arrays below (passed in as parameters); use array.some to evaluate if e.key is in array (k is each element); return true if func() and array.some both true; func() being true is important as not all say keydown would need to trigger moveGrid (e.g. end of game, etc.)

		checkKey(["ArrowLeft"], () => {
			moveGrid(pushLeft);
			localStorage.setItem("numMove", numMove+1);
			setNumMove(numMove+1);
		});

		checkKey(["ArrowRight"], () => {
			moveGrid(pushRight);
			localStorage.setItem("numMove", numMove+1);
			setNumMove(numMove+1);
		});

		checkKey(["ArrowUp"], () => {
			moveGrid(pushUp);
			localStorage.setItem("numMove", numMove+1);
			setNumMove(numMove+1);
		});

		checkKey(["ArrowDown"], () => {
			moveGrid(pushDown);
			localStorage.setItem("numMove", numMove+1);
			setNumMove(numMove+1);
		});

		checkKey("/", () => {
			//window.localStorage.clear(); cannot use this as this will reset isPracticeMode
			localStorage.setItem("numMove",0);
			localStorage.setItem("score",0);

			setGrid(makeGrid(4));
			setNumMove(0);
			setScore (0);
		});


		//console.log(numMove);
	
		/* don't allow undo
		checkKey("z", () => {
			setGrid(lastGrid);
			setLost(isGameOver(lastGrid));
			setWon(isWon(lastGrid));
		}); */
	});

	//wrapper to convert touch to keypress and pass it to useKeypress hook; https://stackoverflow.com/questions/68619239/touch-support-for-arrow-clicks-on-react-app
	const handleTouch = (key) => {
		window.dispatchEvent(new KeyboardEvent('keydown', {
			'key': key
		  }));
	}

	const swipeHandlers = useSwipeable({ //useSwipeable hook
		onSwipedLeft: () => handleTouch('ArrowLeft'),
		onSwipedUp: () => handleTouch('ArrowUp'),
		onSwipedRight: () => handleTouch('ArrowRight'),
		onSwipedDown: () => handleTouch('ArrowDown'),
		preventScrollOnSwipe: true
	});

	const reset = (e) => {
		e.preventDefault();
		e.stopPropagation();
		window.dispatchEvent(new KeyboardEvent('keydown', {
			'key': '/'
		}));
	}


    const handleChainSelect = async (value) => {
        console.log(`selected network is ${value}`);
        //await switchToSelectedChain(value);
    }

	
return ( //md:flex-row = medium-sized or above (md >= 768px, lg>= 1024px; sm 640px) screen then flex-row; default is flex-col; https://tailwindcss.com/docs/responsive-design

        <div className="container grid-cols-2 flex flex-col md:flex-row max-w-screen-md">
	
            {	isLoading && ( 
                <LoadingSpinner />
                )
            }
            <div className="flex flex-col sm:w-3/5">

                <div className="flex flex-col">
                        <p className="text-xl mb-1 px-3 py-1 text-white w-fit mx-auto"> 
                            2048 Token - Everest (<a className="hover:bg-slate-500" href="https://medium.com/@social_64585/2048-token-everest-the-worlds-first-web3-multi-chain-2048-game-https-2048token-com-b52da3f7188f" rel="noreferrer">Guide</a>)
                        </p>
                </div>

                <div className="md:grid-cols-3 justify-center auto-rows-auto auto-cols-min flex flex-col sm:flex-row">

                    <div className="md:w-1/6 grid3">

                    </div>
                    
                    <div className="md:w-2/3 flex flex-col grid2">
                
                        {!isOnRightChain && (
                            <div className="flex flex-row justify-center mx-1">
                                <div className="grid-cols-2 flex flex-row justify-center w-full">
                                    {/*<OnboardingButton connectWallet={connectWallet} />  */}
                                    <OnboardingButton parentCallback={handleOnboardingCallback} connectWallet={connectWallet} />

                                </div>
                            </div>
                            )}
                        <div className="flex flex-row mx-1 justify-between">
                            {isOnRightChain && (
                            <div>
                                <p className="text-xs mb-1 px-1 py-1 text-white
                                w-fit rounded drop-shadow-sm">On {networks[networkName.current].chainName}
                                </p>
                            </div>
                            )}
                            {isOnRightChain && (
                            <div className="text-sm mb-1 px-1 py-1 font-medium
                            text-white w-fit rounded drop-shadow-sm">
                            Score: {score}
                            </div>
                            )}
                        </div>

                        <div className="grid-cols-2 flex flex-row justify-between mx-1">
                            {!ifPlayerExist && isOnRightChain && (
                            <div>
                                <button className="focus:outline-none text-sm mb-2 px-3 py-1
                                        text-black-500 bg-gray-200 w-fit rounded drop-shadow-sm hover:bg-sky-500"  
                                        onClick={showModal}>Please Register First
                                </button>
                                <Modal title="Register on Blockchain" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                                    {isLoading && ( <LoadingSpinner />)}
                                    <Registration setUserName={setUserName} setReferrerAddr={setReferrerAddr} 
                                    handleAddPlayerInput={handleAddPlayerInput} isLoading={isLoading} currentAccount={currentAccount} networkName={networkName.current} />
                                </Modal>
                            </div>
                            ) }	

                            {ifPlayerExist && !hasCurrentSession && (
                            <div>	
                                <Tooltip placement="topLeft" title="After your first session, you must use this button to load the last saved board from the blockchain to track progress. This button is disabled when a current session exists. This will also start the session timer. Otherwise, you can only practice. Since we need to write the session start time to the blockchain, this consumes gas. ">
                                    <button className="focus:outline-none text-sm mb-2 px-3 py-1
                                        text-black-500 bg-gray-200 w-fit rounded drop-shadow-sm hover:bg-sky-500" 
                                        onClick={startSession} disabled={isLoading || hasCurrentSession} >
                                        Start a Session
                                    </button>
                                </Tooltip>
                            </div>
                            ) }	
                            {ifPlayerExist && !hasCurrentSession && (
                            <div>
                                <Tooltip placement="topLeft" title="You must save your board to the blockchain within the current session to record your progress and receive 2048 token rewards. This button is disabled when there is not a current blockchain session.">
                                    <button className="focus:outline-none text-sm mb-2 px-3 py-1
                                        text-black-500 bg-gray-200 w-fit rounded drop-shadow-sm hover:bg-sky-500" 
                                        onClick={submit} disabled={isLoading || !hasCurrentSession} >
                                        Save to Blockchain
                                    </button>
                                </Tooltip>
                            </div> 
                            )}


                            { ifPlayerExist && hasCurrentSession && (
                            <div className='justify-center flex flex-col text-sm'>
                                <Tooltip color="volcano" placement="topLeft" title="You must save your board to the blockchain before this session ends.">
                                    <div className='flex flex-row justify-center text-black-500 bg-gray-200/75 my-1 px-3 py-1 w-full rounded'>
                                    
                                        Countdown:&nbsp;<div>
                                                            <Countdown
                                                                date={data.date + data.delay}
                                                                renderer={renderer}
                                                                onStart={(delta) => {
                                                                    
                                                                }}
                                                                onComplete={() => {
                                                                setHasCurrentSession(false);
                                                                //window.location.reload();
                                                                }}
                                            
                                                            />
                                                        </div>
                                        
                                    </div>
                                </Tooltip>	
                                <Tooltip color="volcano" placement="topLeft" title="Only reload the last saved board during a session when you intend to abandon the current board. If you have not saved during the current session, this will load the board saved from your last session.">
                                    <button className="content-center text-sm mb-2 my-1 px-3 py-1 w-full text-black-500 bg-gray-200/75 rounded drop-shadow-sm hover:bg-sky-500" onClick={getCurrentSessionGrid} disabled={isLoading}>
                                        Reload Current Session
                                    </button>
                                </Tooltip>	
                            </div> 				
                            ) }

                            { ifPlayerExist && hasCurrentSession && (
                            <div>		
                                <Tooltip placement="topLeft" title="This button is disabled when there is not a current blockchain session. You must save your board to the blockchain within the current session to record your progress and receive 2048 token rewards. ">
                                    <button className="focus:outline-none text-2xl mb-2 my-1 px-3 py-4
                                        text-black-500 bg-gray-200/75 w-fit rounded drop-shadow-sm hover:bg-sky-500" 
                                        onClick={submit} disabled={isLoading || !hasCurrentSession} >
                                        Save
                                    </button>
                                </Tooltip>
                            </div>
                            )}

            
                        </div>

                        <div {...swipeHandlers} className="noselect bg-stone-300/75 p-1
                            w-fit mx-auto shadow rounded"
                            style={{
                                display: "grid", //tailwind css: grid
                                gridTemplateRows: `repeat(${size}, minmax(0, 1fr))`, 
                                gridTemplateColumns: `repeat(${size}, minmax(0, 1fr))`
                            }}>
                            { //[[1,2,3,4],[1,2,3,4],[1,2,3,4],[1,2,3,4]]
                            //grid.flat().map((c, i) => <Tile key={i} content={c} size={size} /*newTilekey={} */ />)
                            //Tile component from tile.jsx file;make grid one-dimensional and assign value (c) to props content and index to key; 
                                grid.flat().map((c, i) => {/* <Tile key={i} content={c} size={size} />  */ 
                                
                                if (i === newTilePos ) {
                                    return ( <NewTile key={i} content={c} size={size} /> 
                                    );
                                }
                                    return (<Tile key={i} content={c} size={size} /> ); //return Tile if condition not met
                                }
                                )
                            }	
                        </div>
                    
                        <div>
                    
                            <div className="text-sm mx-1 my-3 p-3 bg-gray-200/75 rounded drop-shadow-sm w-fit px-4">
								{ isDesktopOrLaptop && <span>Use <code>&uarr;&larr;&darr;&rarr;</code> to </span>}
								{ isTabletOrMobile && <span>Swipe to </span> }
								<span>move tiles and tiles with the same number will merge into one.</span>
                            { /*<p>Use <code>Z</code> to undo last move.</p> */ }
                            </div>
                
                        
                        { won &&
							<Modal title="Congratulation!" visible={isModalVisible4} onOk={handleOk4} onCancel={handleCancel4} footer={null}>
								<div className="text-sm my-3 p-3 bg-blue-400/75 text-gray-100 rounded drop-shadow-sm w-fit">
									<p>Amazing, You reached level 9 - the 2048 Everest Summit! This is almost impossible to achieve! Save your board now to receive {levelRewardRate[9]} {levelRewardRate[9]<=1?'token':'tokens'} per saved session hour while you are on the Summit!</p>
								</div>
							</Modal>
                        }
                        { lost &&
								<Modal title="Game Over!" visible={isModalVisible5} onOk={handleOk5} onCancel={handleCancel5} footer={null}>
									<div className="text-sm my-3 p-3 bg-red-400/75 text-gray-100 rounded drop-shadow-sm w-fit">
										<p>Cannot make any move, Game Over!</p>
										<p>Press the Reset Board button to start over. Use the Reload Current Session button to load your last saved board. Caution: If you save to blockhchain now, you will overwrite your last saved board with this losing one!</p> {/*	or press <code>Z</code> to undo last move</p> */ }
									</div>
								</Modal>
						
						}

						{ reach512 &&
								<Modal title="You reached Level 1!" visible={isModalVisible6} onCancel={handleCancel6} footer={null}>
									<div className="text-sm my-3 p-3 bg-blue-400/75 text-gray-100 rounded drop-shadow-sm w-fit">
										<p>You reached Level 1 - Foothill of Himalayas! Save your board now to receive {levelRewardRate[1]} {levelRewardRate[1]<=1?'token':'tokens'} per saved session hour while you are on this level. </p>
									</div>
								</Modal>
						}

						{ reach1024 &&
								<Modal title="You reached Level 2!" visible={isModalVisible7} onCancel={handleCancel7} footer={null}>
									<div className="text-sm my-3 p-3 bg-blue-400/75 text-gray-100 rounded drop-shadow-sm w-fit">
										<p>You reached Level 2 - Tourist Camp! Save your board now to receive {levelRewardRate[2]} {levelRewardRate[2]<=1?'token':'tokens'} per saved session hour while you are on this level. </p>
									</div>
								</Modal>
						}

						{ reach2048 &&
								<Modal title="You reached Level 3!" visible={isModalVisible8} onCancel={handleCancel8} footer={null}>
									<div className="text-sm my-3 p-3 bg-blue-400/75 text-gray-100 rounded drop-shadow-sm w-fit">
										<p>You reached Level 3 - Base Camp! Save your board now to receive {levelRewardRate[3]} {levelRewardRate[3]<=1?'token':'tokens'} per saved session hour while you are on this level. </p>
									</div>
								</Modal>
						}

						{ reach4096 &&
								<Modal title="You reached Level 4!" visible={isModalVisible9} onCancel={handleCancel9} footer={null}>
									<div className="text-sm my-3 p-3 bg-blue-400/75 text-gray-100 rounded drop-shadow-sm w-fit">
										<p>You reached Level 4 - Khumbu Icefall! Save your board now to receive {levelRewardRate[4]} {levelRewardRate[4]<=1?'token':'tokens'} per saved session hour while you are on this level. </p>
									</div>
								</Modal>
						}

						{ reach8192 &&
								<Modal title="You reached Level 5!" visible={isModalVisible10} onCancel={handleCancel10} footer={null}>
									<div className="text-sm my-3 p-3 bg-blue-400/75 text-gray-100 rounded drop-shadow-sm w-fit">
										<p>You reached Level 5 - Camp 1! Save your board now to receive {levelRewardRate[5]} {levelRewardRate[5]<=1?'token':'tokens'} per saved session hour while you are on this level. </p>
									</div>
								</Modal>
						}

						{ reach16384 &&
								<Modal title="You reached Level 6!" visible={isModalVisible11} onCancel={handleCancel11} footer={null}>
									<div className="text-sm my-3 p-3 bg-blue-400/75 text-gray-100 rounded drop-shadow-sm w-fit">
										<p>You reached Level 6 - Camp 2! Save your board now to receive {levelRewardRate[6]} {levelRewardRate[6]<=1?'token':'tokens'} per saved session hour while you are on this level. </p>
									</div>
								</Modal>
						}

						{ reach32768 &&
								<Modal title="You reached Level 7!" visible={isModalVisible12} onCancel={handleCancel12} footer={null}>
									<div className="text-sm my-3 p-3 bg-blue-400/75 text-gray-100 rounded drop-shadow-sm w-fit">
										<p>You reached Level 7 - Camp 3! Save your board now to receive {levelRewardRate[7]} {levelRewardRate[7]<=1?'token':'tokens'} per saved session hour while you are on this level. </p>
									</div>
								</Modal>
						}

						{ reach65536 &&
								<Modal title="You reached Level 8!" visible={isModalVisible13} onCancel={handleCancel13} footer={null}>
									<div className="text-sm my-3 p-3 bg-blue-400/75 text-gray-100 rounded drop-shadow-sm w-fit">
										<p>You reached Level 8 - Camp 4! Save your board now to receive {levelRewardRate[8]} {levelRewardRate[8]<=1?'token':'tokens'} per saved session hour while you are on this level. </p>
									</div>
								</Modal>
						}
						
                        </div>
                    
                    </div>
                        
                    <div className="flex flex-col w-1/6 grid3">

                    </div>
                </div>				

                <br />
            
                { /*  
                {allSubmits.map((submit, index) => {
                return (
                    <div key={index} style={{ backgroundColor: "OldLace", marginTop: "16px", padding: "8px" }}>
                    <div>Address: {submit.address}</div>
                    <div>Time: {submit.timestamp.toString()}</div>
                    <div>Score: {submit.score}</div>
                    </div>)
                })} */ }
            
            </div>

            <div className="sm:w-2/5 justify-center mx-2" >

                <div className="justify-center flex flex-col">
                    <div>
                        <button className="focus:outline-none text-xs my-3 px-2 py-1 mb-5
                                text-white bg-slate-800 w-full rounded-full drop-shadow-sm hover:bg-sky-500"  
                                onClick={showModal3}>
                                Switch Blockchain Network
                        </button>
                        <Modal title="Switch to Another Blockchain Network" visible={isModalVisible3} onOk={handleOk3} onCancel={handleCancel3} footer={null}>
                            <ChangeNetwork isLoading={isLoading} chainId={chainId.current} />
                        </Modal>
                    </div>
                    <div>
                        <Tooltip placement="topLeft" title="Offchain Practice Mode allows you to practice offline without starting a blockchain session. Practice mode is disabled automatically after you start a new onchain session. You cannot save to the blockchain while in practice mode. If this mode is off, you won't be able to load offchain board data after you refresh your browser. ">
                            <Switch  onChange={practiceModeSwitch} checkedChildren="Off-chain Practice Mode On" 
                            unCheckedChildren="Off-chain Practice Mode Off" checked={localStorage.getItem('isPracticeMode') === "true"} disabled={hasCurrentSession || isLoading}
                            className="text-sm mb-5
                            w-full text-white text-center bg-gray-800 rounded-full hover:bg-sky-500"/>
                        </Tooltip> 
                    </div>
					{ isOnRightChain &&
						<div className="flex flex-row content-around">
							<button className="focus:outline-none text-xs mx-2 my-3 px-2 py-1 mb-5
									text-white bg-slate-800 w-full rounded-full drop-shadow-sm hover:bg-sky-500"  
									onClick={showModal14}>
									Leaderboard
							</button>
							{isTabletOrMobile &&
								<Modal title="Leaderboard" visible={isModalVisible14} onCancel={handleCancel14} footer={null}>
									<table className="min-w-full">
										<thead className="border-b">
											<tr>
												<th scope="col" className="text-sm font-medium text-gray-900 md:px-6 sm:px-2 py-4 text-left">Rank</th>
												<th scope="col" className="text-sm font-medium text-gray-900 md:px-6 sm:px-2 py-4 text-left">Address</th>
												<th scope="col" className="text-sm font-medium text-gray-900 md:px-6 sm:px-2 py-4 text-left">Level</th>
												<th scope="col" className="text-sm font-medium text-gray-900 md:px-6 sm:px-2 py-4 text-left">High Score</th>
											</tr>
										</thead>
										<tbody>
											{topPlayers.map((player, i) => <PlayersRankMobile key={i} rank={i+1} playerAddress={player.playerAddress} level={player.level} bestScore={player.bestScore}/>)}
										</tbody>
									</table>
								</Modal>
							}

							{isDesktopOrLaptop &&
								<Modal className="min-w-[70%]" title="Leaderboard" visible={isModalVisible14} onCancel={handleCancel14} footer={null}>
									<table className="min-w-full">
										<thead className="border-b">
											<tr>
												<th scope="col" className="text-sm font-medium text-gray-900 md:px-6 sm:px-2 py-4 text-left">Rank</th>
												<th scope="col" className="text-sm font-medium text-gray-900 md:px-6 sm:px-2 py-4 text-left">Address</th>
												<th scope="col" className="text-sm font-medium text-gray-900 md:px-6 sm:px-2 py-4 text-left">Player Name</th>
												<th scope="col" className="text-sm font-medium text-gray-900 md:px-6 sm:px-2 py-4 text-left">Level</th>
												<th scope="col" className="text-sm font-medium text-gray-900 md:px-6 sm:px-2 py-4 text-left">High Score</th>
											</tr>
										</thead>
										<tbody>
											{topPlayers.map((player, i) => <PlayersRankDesktop key={i} rank={i+1} playerAddress={player.playerAddress} playerName={player.playerName} level={player.level} bestScore={player.bestScore}/>)}
										</tbody>
									</table>
								</Modal>
							}

				
							<button className="focus:outline-none text-xs mx-2 my-3 px-2 py-1 mb-5
										text-white bg-slate-800 w-full rounded-full drop-shadow-sm hover:bg-sky-500" onClick={reset}>
								Reset
							</button>
						</div>
					}		
               
                </div>

                { ifPlayerExist &&	(
                
                <div className="flex flex-col justify-center">

                    <Tooltip placement="topLeft" title="This displays player information and update your best score and level depending on the max tile you have achieved AND saved to the blockchain. Levels are: Ground Zero before tile 2048, Base Camp - 2048, Icefall - 4096, Camp 1 - 8192, Camp 2 - 16384, Camp 3 - 32768, Camp 4 - 65536, Summit - 131072.">
                        <button className='w-full text-base mb-1 my-7 px-3 py-1 text-black-500 bg-gray-200 drop-shadow-sm hover:bg-sky-500' onClick={()=> displayCurrentPlayer(currentAccount)}>
                            Refresh Player Status 
                        </button>
                    </Tooltip>

                    <div className="bg-gray-200/75 px-3 text-black-500"> 
                        <p className='w-full text-sm my-2 text-black-500 font-bold drop-shadow-sm'>
                            2048 Token Balance: {playerCMGBalance}
                        </p>
                        <CurrentPlayer playerAddress={currentPlayer.playerAddress} playerName={currentPlayer.playerName} bestScore={currentPlayer.bestScore} 
                        level={currentPlayer.level} joinedAt={currentPlayer.joinedAt} isActivated={currentPlayer.isActivated} rewardBal={currentPlayer.rewardBal}/> 
                    </div>
                    
                    <Tooltip placement="topLeft" title="As this is a blockchain transaction, you might want to accumulate the rewards for a while and then claim all rewards at once. Once the reward is claimed, the balance will be reset to 0. Your wallet token balance will be updated too.">
                        <button className='text-center w-full text-base mb-1 my-10 px-3 py-1 text-black-500 bg-gray-200 drop-shadow-sm hover:bg-sky-500' 
                            onClick={claimReward} disabled={isLoading || !rewardBalance}>
                                Claim Token Rewards: {rewardBalance}
                        </button>
                    </Tooltip>

                    <div className='bg-gray-200/75 text-black-500 px-3 py-3 justify-right flex flex-col text-sm'>
						<span>Reward is based on your level and the total <b>saved</b> blockchain session time on that level. The higher the level, the more rewards you receive for the same session time. To save gas fees, you can accumulate rewards for a period and then claim all at once.</span>	
                    </div>


                </div>	
                )}	
                
                { isAmbas && ( // if is Ambassador, even without a player account, ambas can earn rewards.
                <div className='border border-slate-300 text-base my-10 drop-shadow-sm'>
                    <p className='text-center w-full text-base mb-1 px-3 py-1 text-black-500 bg-gray-200 drop-shadow-sm' >
                        Ambassador Rewards (for Referrers): 
                    </p>

                    <button className='w-full text-sm mb-1 my-3 px-3 py-1 text-black-500 bg-gray-200 drop-shadow-sm hover:bg-sky-500' 
                            onClick={getAmbasRewardBalance} disabled={isLoading}>
                            Update Ambassador Reward: {ambasRewardBalance}
                    </button>
                    
                    <button className='w-full text-sm mb-1 my-3 px-3 py-1 text-black-500 bg-gray-200 drop-shadow-sm hover:bg-sky-500' 
                        onClick={ambasClaimReward} disabled={isLoading || !ambasRewardBalance}>Claim Ambassador Reward: {ambasRewardBalance}
                    </button>
                    <div className='bg-gray-200/75 mt-3 py-3 text-black-500 flex flex-col text-sm px-3 '>
                        Reward is calculated based on 5% of all your referrals' rewards. To save gas fees, you can accumulate rewards for a period and then claim all at once. 	
                    </div>
                </div>
                ) }

                { currentAccount == '0x2b9d3e68826e7139507b14588557a92b133ebd01' && (
                <div className='text-base mb-1 px-3 py-1 text-black-500 bg-gray-200 drop-shadow-sm'>
                    <div>Admin Only - Account Activation!
                        <input placeholder="Address to Activate" onChange={e => setAddress(e.target.value)} />
                        <button onClick={() => activatePlayer(address)}>Activate!</button>
                    </div><br />
                    <div>Admin Only - Account Deactivation!
                        <input placeholder="Address to Deactivate" onChange={e => setAddress(e.target.value)} />
                        <button onClick={() => deactivatebyOwner(address)}>Deactivate!</button>
                    </div><br />
                    <div>Admin Only - Balance of CMG!
                        <input placeholder="Check the Balance of Token" onChange={e => setAddress(e.target.value)} />
                        <button onClick={() => getBalanceOfCMG(address)}>Current Token Balance: {playerCMGBalance}</button>
                    </div><br />

                </div>
                )}
                
            </div>
        </div>
	);
};

export default App;
