const makeGrid = size => {
	const grid = new Array(size);
	for (let i = 0; i < grid.length; i++) {
		grid[i] = new Array(size).fill(0);
	}
	return generateNewTile(grid)
}

const transpose = grid => grid[0].map((_, i) => grid.map(r => r[i]));

const rotateCW = grid => transpose(grid).map(r => r.reverse());

const rotateCCW = grid => transpose(grid).reverse();

//let myScore =0;
//let rowScore = 0; 
//let numMergeRowcall = 0; 
const mergeRow = row => {
	//numMergeRowcall = numMergeRowcall +1
	//console.log ('numMergeRowcall call #: ', numMergeRowcall)
	//console.log('INput row is: ', row)
	const len = row.length; //initial row.length = grid size (default is 4), row.length will change below, but len will not. 
	//console.log('len',len)
	row = row.filter(v => v !== 0); //return a new row (array) with tiles' value > 0; if 0 is in the middle of two non-zero tiles, those two tiles are neighbor!
	let i = 0;
	while (i < row.length -1 ) {
		//console.log ('rowlength -1 : ', row.length -1)
		//console.log('current i: ',i)
		//console.log('current row is: ', row)
		if (row[i] === row[i + 1]) {
			row[i] *= 2; // merge two tiles towards row[i]; 
			row.splice(i + 1, 1); //remove row[i+1] as it is 0 now;
			//rowScore = row[i];
			//myScore = myScore + rowScore;
			//console.log('rowScore is: ', rowScore);
			//console.log('myScore is: ', myScore);
		}
		i += 1;
		
	}
	return row.concat(new Array(len - row.length).fill(0)); // fill the rest of the row with 0 after merging
}

const pushLeft = grid => deepCopy(grid).map(row => mergeRow(row));
const pushRight = grid => deepCopy(grid).map(row => mergeRow(row.reverse()).reverse());
const pushUp = grid => rotateCW(pushLeft(rotateCCW(grid)));
const pushDown = grid => rotateCCW(pushLeft(rotateCW(grid)));

const generateNewTile = (grid) => {
	// setTimeout(() => {console.log("wait 5 s!"); }, 5000);
	const randInt = max => Math.floor(Math.random() * max);
	let x = 0;
	let y = 0;
	do { //find the random tile grid[x][y] that has a value of 0.
		x = randInt(grid.length); //expected 0, 1, 2 or 3
		y = randInt(grid[0].length); //expected 0, 1, 2 or 3
	} while (grid[x][y] !== 0);
	//grid[x][y] = 4; change to random number 90% for 2 and 10% for 4

	grid[x][y] = Math.random() < 0.9 ? 2 : 4;
	//grid[0][2] = 100;
	return grid;
}


const deepCopy = grid => grid.map(row => row.map(v => v));
//var grid1 = [[2,2,2,2],[2,2,2,2],[2,2,2,2],[2,2,2,2]]
//console.log('deepCopy: ', deepCopy(grid1))

// dirty way of comparing 2 arrays
const isGridsEqual = (g1, g2) => JSON.stringify(g1) === JSON.stringify(g2);

const moveTiles = (grid, moveFunc) => {
	const bk = moveFunc(deepCopy(grid)); // only work on the deepCopy of the current grid
	return (isGridsEqual(grid, bk)) ? grid : generateNewTile(bk) //generate a new tile after move; moveFunc can be pushLeft, pushRight, pushUp, or puDown
}

const isWon = grid => grid.some(row => row.some(val => val >= 131072));

const isGameOver = grid =>
	isGridsEqual(grid, moveTiles(deepCopy(grid), pushLeft)) &&
	isGridsEqual(grid, moveTiles(deepCopy(grid), pushRight)) &&
	isGridsEqual(grid, moveTiles(deepCopy(grid), pushUp)) &&
	isGridsEqual(grid, moveTiles(deepCopy(grid), pushDown))

const isFull = grid => grid.every(r => r.every(v => v !== 0));

const calRowScore = row => {

	//console.log ('Initial score after calling before into while loop: ', score)

	//console.log('INput row is: ', row)
	const len = row.length; //initial row.length = grid size (default is 4), row.length will change below, but len will not. 
	//console.log('len',len)
	row = row.filter(v => v !== 0); //return a new row (array) with tiles' value > 0; if 0 is in the middle of two non-zero tiles, those two tiles are neighbor!
	let i = 0;
	let rowScore = 0; //store each row's score

	while (i < row.length -1 ) {

		//console.log ('rowlength -1 : ', row.length -1)
		//console.log('current i: ',i)
		//console.log('current row is: ', row)

		if (row[i] === row[i + 1]) {
			row[i] *= 2; // merge two tiles towards row[i]; but not deleting the row[i+1]
			//console.log('rowScore is: ', row[i]);
			rowScore = rowScore + row[i]; //pass each rowScore to accumulate
			i = i+1 // skipping row[i+1] so that the next comparison starts with row[i+2]
		}
		i += 1;
	}
	//console.log ('rowScore out of loop is: ', rowScore);
	//setScore(score + rowScore);	//Move this out of loop as React setState cannot be in a loop; https://stackoverflow.com/questions/35248748/calling-setstate-in-a-loop-only-updates-state-1-time
	return rowScore; // return each rowScore to pushLeftScoreArray
}

const gridTo1DArray = (grid) => { //convert 2D array to 1D
	return grid.flat();
}

const flatArrayTo2D = (list, elementsPerSubArray) => { //convert 1D array to 2D
	const matrix = [];
	while(list.length) matrix.push(list.splice(0,elementsPerSubArray));
	return matrix;
}

const convertHexListToNumList = (list) => { //convert bigNumber uint256 1D array to javascript number array
	return list.map ( v => v.toNumber());
}

export {
	makeGrid,
	moveTiles,
	pushLeft,
	pushRight,
	pushUp,
	pushDown,
	isFull,
	isWon,
	isGameOver,
	isGridsEqual,
	generateNewTile,
	rotateCW,
	deepCopy,
	gridTo1DArray,
	calRowScore,
	flatArrayTo2D,
	convertHexListToNumList,

}