//work on line 107 and 137; if all accounts disconnected, should not show Please Register First; useRef, forwardRef, useIMperativeHandle for parent to call child component functions; https://www.codingdeft.com/posts/react-calling-child-function-from-parent-component/
import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react'; 
import { networks, chainIDs, } from "./Constants.jsx";
import ErrorMessage from "./ErrorMessage";

import MetaMaskOnboarding from '@metamask/onboarding' //https://medium.com/coinmonks/create-an-avalanche-dapp-with-ethers-metamask-and-react-342d8d22cb30
import {isMobile, isDesktop, isAndroid, isIOS, } from 'react-device-detect';

import { Modal, } from 'antd';
import 'antd/dist/antd.min.css'

const metaMaskDeeplink = `https://metamask.app.link/dapp/app.2048token.com`;


const getChainId = async () => {
    let chainId;

    const { ethereum } = window; //matemask injected
    //detect current connected network chainId
    await window.ethereum  //must use await here!!! otherwise, the detectedChainId cannot be passed to return!!!
    .request({ method: 'eth_chainId' })
    .then ( detectedChainId => {
        chainId = detectedChainId;
        //console.log("getChainId(): detectedChainId is: ", chainId);    
    });  

    return chainId; //https://stackoverflow.com/questions/37533929/how-to-return-data-from-promise; https://stackoverflow.com/questions/37533929/how-to-return-data-from-promise
}

// Check if the chainName id is among the allowed networks (obj from Constants.jsx); use "key" in obj to determine if networkName is in networks; https://stackoverflow.com/questions/1098040/checking-if-a-key-exists-in-a-javascript-object
const isAmongNetworks = async () => { //chainIDs is an array from Constants.jsx
    let isRightChain, chainId;
    chainId = await getChainId();
    isRightChain = chainId && chainIDs.includes(chainId.toLowerCase());
    //console.log ("isAmongNetworks: isRightChain result is:", isRightChain);
    return isRightChain;
}

const isAmongNetworks2 = (chainId) => ( //chainIDs is an array from Constants.jsx
    chainId && chainIDs.includes(chainId.toLowerCase())
)

//Onboarding Component
const OnboardingButton = (props) => {

    const onboarding = new MetaMaskOnboarding ();
    const [accounts, setAccounts] = useState([]);
    const [chainId, setChainId] = useState(""); //initially when onboarding, chainId could be null (before installing metamask, etc.); should not specify any default chain;
    const [isRightNetwork, setIsRightNetwork] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);

    const showModal2 = () => {
        setIsModalVisible2(true);
    };

    const handleOk2 = () => {
        setIsModalVisible2(false);
    };

    const handleCancel2 = () => {
        setIsModalVisible2(false);
    };

    const onFocus = () => {
        if (typeof window !== "undefined" && typeof window.ethereum !== 'undefined' &&  !window.ethereum._state.initialized) {
        if(!window.ethereum._state.initialized) {
            window.location.reload();
        }
        }
    }


    //componentDidMount () { //similar to useEffect, execute upon component is rendered
    useEffect(() => {
        if (MetaMaskOnboarding.isMetaMaskInstalled()) {

            let currentAccts = [];
            let isRightChain = false;

            window.addEventListener("focus", onFocus); // use onFocus () to refresh window only when metamask is not yet initialized; temporary fix for https://github.com/MetaMask/metamask-extension/issues/9407

            connectMetaMask();

            // Update the list of accounts if the user switches accounts in MetaMask
            window.ethereum.on('accountsChanged', accounts => {
                setAccounts(accounts);
                currentAccts = accounts;
                window.location.reload(); //06/20/2022 newly added to refresh page after account change
            }); //listener to detect any account change; 

            // Reload the site if the user selects a different chain
            window.ethereum.on('chainChanged', () => window.location.reload()); //listener to detect any network change; if changed, reload window; 

            // Set the chain id once the MetaMask wallet is connected
            window.ethereum.on('connect', (connectInfo) => { //listener to detect account to network connection events; this will not fire if account is already connected even after window reload; 
                const currentChainId = connectInfo.chainId;
                setChainId(currentChainId.toLowerCase());
                //console.log("useEffect chainId:",currentChainId);
                if (isAmongNetworks2(currentChainId)){
                    setIsRightNetwork(true);
                    isRightChain = true;

                    props.parentCallback(currentAccts, currentChainId, isRightChain); //pass child component data to parent App component via props parentCallback's handleCa

                }
            })
            
        }
    }, []); 

    
    const connectMetaMask = async () => { // During onboarding right after installing metamask, click Connect you Wallet button, error: Unchecked runtime.lastError: Could not establish connection. Receiving end does not exist. https://github.com/MetaMask/metamask-extension/issues/9407
        // Request to connect to the MetaMask wallet
        let currentAccts = [];
        let currentChainId;
        let isRightChain = false;
        try {
            const { ethereum } = window; //matemask injected
            if (!ethereum) {
                return;
            }

            await window.ethereum
            .request({ method: 'eth_requestAccounts' })
            .then(accounts => {
                setAccounts( accounts ); //avoid error of Can't perform a React state update on an unmounted component.  
                currentAccts = accounts; 
            });

            await window.ethereum  //this makes sure to update chainId upon user clicking Connect your Wallet button and useEffect (has this function) 
            .request({ method: 'eth_chainId' })
            .then ( detectedChainId => { 
                currentChainId = detectedChainId;
                setChainId(currentChainId.toLowerCase());
                //console.log("connectMetaMask(): detectedChainId is: ", currentChainId);   
                if (isAmongNetworks2(currentChainId)){
                    setIsRightNetwork(true);
                    isRightChain = true;
                } 
            });  

            props.parentCallback(currentAccts, currentChainId, isRightChain); //pass child component data to parent App component via props parentCallback's handleCa
            
        } catch(err){
            console.log(err);
        }
    }


//rendering starts
   
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        if (accounts.length > 0) {
            // If the user is connected to MetaMask, stop the onboarding process.
            onboarding.stopOnboarding()
        }
    }

    if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
    // If MetaMask is not yet installed, ask the user to start the MetaMask onboarding process
    // (install the MetaMask browser extension).
        return (
            <div className="justify-center">
                <p className="text-center text-sm mb-3 mx-3 py-2 text-white w-fit mx-auto">
                    You need the MetaMask Wallet App or Browser Extension installed.
                </p>
            { isDesktop && (
                <button className="focus:outline-none text-xl5 mb-3 mx-0 px-3 py-1
                    text-black-500 bg-gray-200/75
                    w-full text-center rounded drop-shadow-sm hover:bg-sky-500" onClick={onboarding.startOnboarding}>
                Install MetaMask Browser Extension
                </button>
            )}
            
            { isIOS && (
            <div className="justify-center">
                <button className="focus:outline-none text-sm mb-3 mx-0 px-3 py-1
                    text-black-500 bg-gray-200/75
                    w-full rounded drop-shadow-sm hover:bg-sky-500" onClick={()=> {
                        window.open("https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202", "_blank");
                    }}>
                    Download MetaMask App
                </button>
                <p className="text-center mb-3 text-white">OR</p>
                <button className="focus:outline-none text-sm mb-3 mx-0 px-3 py-1
                    text-black-500 bg-gray-200/75
                    w-full rounded drop-shadow-sm hover:bg-sky-500" onClick={()=> {
                        window.open(metaMaskDeeplink, "_blank");
                    }}>
                    Open MetaMask Browser (if installed already)
                </button>
            </div>
            )}
            { isAndroid && (
                <div className="justify-center">
                    <button className="focus:outline-none text-sm mb-3 mx-0 px-3 py-1
                            text-black-500 bg-gray-200/75
                            w-full rounded drop-shadow-sm hover:bg-sky-500" onClick={()=> {
                            window.open("https://metamask.app.link/bxwkE8oF99", "_blank");}}>
                            Download MetaMask App
                    </button>
                    <p className="text-center mb-3 text-white">OR</p>
                    <button className="focus:outline-none text-sm mb-3 mx-0 px-3 py-1
                            text-black-500 bg-gray-200/75
                            w-full rounded drop-shadow-sm hover:bg-sky-500" onClick={()=> {
                            window.open(metaMaskDeeplink, "_blank");}}>
                            Open MetaMask Browser (if installed already)
                    </button>
                
                </div>
                )
            }

            </div>
        )
    } else if (accounts.length === 0) {
    // If accounts is empty the user is not yet connected to the MetaMask wallet.
    // Ask the user to connect to MetaMask.
        return (
            <div className="justify-center">
                <p className="text-center text-sm mb-3 py-2 mx-3 text-white w-full mx-auto">
                You need to connect your MetaMask Wallet.
                </p>
                <button className="focus:outline-none text-sm mb-3 px-3 py-1
                text-black-500 bg-gray-200/75
                w-full rounded drop-shadow-sm hover:bg-sky-500" onClick={connectMetaMask}>
                Connect your Wallet
                </button>
            </div>
        )
    //} else if (!isSelectedChain(chainId)) {
    } else if (!isRightNetwork) {
        // If the selected chain id is not the Avalanche chain id, ask the user to switch
        // to Avalanche.
        return (
            <div className="justify-center">
                <p className="text-center text-sm mb-3 mx-3 py-2 text-white w-fit mx-auto">
                    This chain (Id: {chainId}) is not supported for now. you need to switch to a supported network.
                </p>
                {/*<button className="focus:outline-none text-sm mb-3 px-3 py-1
                    text-black-500 bg-gray-200/75
                    w-full rounded drop-shadow-sm hover:bg-sky-500" onClick={switchToSelectedChain}>
                Switch to {defaultNetwork.chainName} Network
                </button> */}
                <button className="focus:outline-none text-sm mb-2 px-3 py-1
                        text-black-500 bg-gray-200 w-fit rounded drop-shadow-sm hover:bg-sky-500"  
                        onClick={showModal2}>Select Blockchain Network
                </button>
                <Modal title="Switch to Another Blockchain Network" visible={isModalVisible2} onOk={handleOk2} onCancel={handleCancel2} footer={null}>
                    <ChangeNetwork chainId={chainId}/>
                </Modal>
                        
            </div>
        )

    } else {
        // The user is connected to the MetaMask wallet and has a compatible chain selected.
        return (
            <div className="justify-center">
                <p className="text-center text-white">This chain (Id: {chainId}) is supported.</p>
                <button className="focus:outline-none text-xl5 mb-3 px-3 py-1
                        text-black-500 bg-gray-200/75
                        w-full rounded drop-shadow-sm hover:bg-sky-500" onClick={() => props.parentCallback(accounts, true)}>
                        Connect
                </button>

            </div>
        )
    }   
}


 //allow users to switch a specific supported network via networkName argument (user selected): https://codesandbox.io/s/react-metamask-network-switch-co6h6?file=/src/App.js:1230-1583
 const changeNetwork = async ({ networkName, chainId, setError }) => { 

    //https://github.com/MetaMask/metamask-extension/issues/10597#issuecomment-791525135; must switch first before add so that if switching to default network, it won't throw error: May not specify default MetaMask chain.    
    try {
        if (!window.ethereum) throw new Error("No crypto wallet found");

        await window.ethereum.request({
            method: 'wallet_switchEthereumChain', //try to switch first before adding a new network as we cannot add default metamask network 
            params: [{ chainId: chainId }],
        });

    } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902 || switchError.code === -32603) {// -32603 is the error code for mobile
            try {
                await window.ethereum.request({
                method: "wallet_addEthereumChain", //For any of the default MetaMask networks, you'll need to use wallet_switchEthereumChain instead of wallet_addEthereumChain, providing only the chainId in the request; 
                params: [
                    {
                    ...networks[networkName] //when key is a variable, must use obj[] to reference; if key is the actual string, can use obj.key to reference
                    }
                ]
            });

            //props.parentCallback1(accounts, networkName, true); //pass child component data to parent App component via props parentCallback's handleCallback function

            } catch (addErr) {
            // handle "add" error
                setError(addErr.message);
            }
            
        }
        // handle other "switch" errors
        setError(switchError.message); 
    }
}

const ChangeNetwork = (props) => {

    const [error, setError] = useState();

    const handleNetworkSwitch = async (networkName, chainId) => {
        setError();
        await changeNetwork({ networkName, chainId, setError });
    };

    const networkChanged = (chainId) => {
    //console.log({ chainId });
    };

    useEffect(() => {
    window.ethereum.on("chainChanged", networkChanged);

    return () => {
        window.ethereum.removeListener("chainChanged", networkChanged);
    };
    }, []);

   // console.log ("ChangeNetwork props.chainId is", props.chainId);

    return (
    <div className="credit-card w-full lg:w-1/2 sm:w-auto shadow-lg mx-auto rounded bg-gray-200">
        <main className="justify-center p-4">
        <div className="justify-center">

            
            { props.chainId !== networks.polygon.chainId &&
                <button
                onClick={() => handleNetworkSwitch("polygon", networks.polygon.chainId)}
                className="text-white bg-violet-500 rounded m-2 p-2 focus:ring focus:outline-none w-full"
                >
                Polygon Mainnet
                </button>
            }

            {/* props.chainId !== networks.Rinkeby_Testnet.chainId &&

                <button
                onClick={() => handleNetworkSwitch("Rinkeby_Testnet", networks.Rinkeby_Testnet.chainId)}
                className="text-white bg-orange-500 rounded-md m-2 p-2 focus:ring focus:outline-none w-full"
                >
                Rinkeby Testnet
                </button>
            */}

            { /*  props.chainId !== networks.Avalanche_Testnet.chainId &&
                <button
                onClick={() => handleNetworkSwitch("Avalanche_Testnet", networks.Avalanche_Testnet.chainId)}
                className="text-white bg-orange-500 rounded-md m-2 p-2 focus:ring focus:outline-none w-full"
                >
                Avalanche Testnet
                </button>
            */}

            {/* props.chainId !== networks.polygon_testnet.chainId &&
                <button
                onClick={() => handleNetworkSwitch("polygon_testnet", networks.polygon_testnet.chainId)}
                className="text-white bg-violet-500 rounded m-2 p-2 focus:ring focus:outline-none w-full"
                >
                Polygon Mumbai Testnet
                </button>
            */}

            {/* props.chainId !== networks.bsc_testnet.chainId &&
                <button
                onClick={() => handleNetworkSwitch("bsc_testnet", networks.bsc_testnet.chainId)}
                className="text-white bg-yellow-500 rounded m-2 p-2 focus:ring focus:outline-none w-full"
                >
                Binance Smart Chain Testnet
                </button>
            */}

            {/* props.chainId !== networks.velas_testnet.chainId &&
                <button
                onClick={() => handleNetworkSwitch("velas_testnet", networks.velas_testnet.chainId)}
                className="text-white bg-yellow-500 rounded m-2 p-2 focus:ring focus:outline-none w-full"
                >
                Velas Testnet
                </button>
            */}

            { props.chainId !== networks.celo.chainId &&
                <button
                onClick={() => handleNetworkSwitch("celo", networks.celo.chainId)}
                className="text-white bg-yellow-500 rounded m-2 p-2 focus:ring focus:outline-none w-full"
                >
                Celo Mainnet
                </button>
            }

            {/* props.chainId !== networks.celo_testnet.chainId &&
                <button
                onClick={() => handleNetworkSwitch("celo_testnet", networks.celo_testnet.chainId)}
                className="text-white bg-yellow-500 rounded m-2 p-2 focus:ring focus:outline-none w-full"
                >
                Celo (Alfajores Testnet)
                </button>
            */}

            <ErrorMessage message={error} />
        </div>
        </main>
    </div>
    );
}




export {
    OnboardingButton, ChangeNetwork,
    isAmongNetworks,
}