import React from "react";

const tileColor = { // key value style object; can use tileColor[key] to reference value;
	// javascript object key cannot be numbers; the below keys are treated as string automatically; https://www.becomebetterprogrammer.com/can-javasscript-object-keys-be-numbers-or-non-string-values/#:~:text=Against%20what%20many%20think%2C%20JavaScript,to%20a%20string%20a%20value.
	2: "bg-sky-400",
	4: "bg-yellow-400",
	8: "bg-teal-400",
	16: "bg-blue-400",
	32: "bg-green-400",
	64: "bg-lime-400",
	128: "bg-pink-400",
	256: "bg-orange-400",
	512: "bg-indigo-400",
	1024: "bg-violet-400",
	2048: "bg-purple-400",
	4096: "bg-fuchsia-400",
	8192: "bg-rose-400",
	16384: "bg-green-800",
	32768: "bg-blue-800",
	65536: "bg-red-800",
	131072: "bg-violet-800"
};

const getSize = size => {
	if (size < 5) {
		return "w-16 h-16 text-2xl m-1"
	} else if (size < 7) {
		return "w-20 h-20 text-2xl m-2"
	} else if (size < 9) {
		return "w-16 h-16 text-xl m-1"
	} else {
		return "w-12 h-12 text-base m-1"
	}
}

const Tile = (props) => {
	return ( // duration-100 100ms is optimial; from tailwind css
		<div className={`${getSize(props.size)} text-gray-100 flex rounded-xl
		justify-center items-center drop-shadow-md
		 hover:scale-105 
		${tileColor[props.content]}`}>
			{props.content || ' ' // display tile content as number string or empty string
			} 
		</div>
	);
};

const NewTile = (props) => { //component in react name must be Pascal style; 
	return (
		<div className={`${getSize(props.size)} temporary-bounce text-gray-100 flex rounded-xl
		justify-center items-center drop-shadow-md
		 hover:scale-105 
		${tileColor[props.content]}`}>
			{props.content || ' ' // display tile content as number string or empty string
			} 
		</div>
	);
};


export {
	Tile,
	NewTile,
	tileColor,
	getSize,
}